import React, { useState, useEffect } from "react";
import {
  View,
  ImageBackground
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useEmpresas, useCaja } from "../Components/uses/useMesas";
import CabeceraSecundariaScreem from "../Components/CabeceraSecundariaScreem";
import { Form } from "react-bootstrap";

export default function CajaAperturada({route}) {
    console.log("ROUTEEEE",route);
  const {EmpresasList,Listar_Empresas,empresaSelect,localSelect}=useEmpresas();
  const navigation = useNavigation();
const {CajasPendientesList,Listar_Cajas}=useCaja();
  useEffect(()=>{
    if(empresaSelect.Id>0)
    {
      console.log("Listando Cajas!!",empresaSelect);
      Listar_Cajas();
    }
  },[empresaSelect])

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      setIsLoading(true);
      Listar_Empresas();
        setIsLoading(false);
  }, []);

  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const handleDateChange = (e) => {
    setMinDate(e.nativeEvent.srcElement.value);
  };
  console.log("max date",maxDate)
  console.log("min date",minDate)
  useEffect(() => {
    setMaxDate(new Date().toISOString().split('T')[0]);
  }, []);

  return (
    <View style={{backgroundColor:"#fff",flex:1}}>
      <CabeceraSecundariaScreem texto="Cajas Aperturadas"  />
         <View style={{flex:1, margin:10, overflow:"scroll"}}>
       {isLoading? <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignContent: "center",
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{ backgroundColor: "#fff", padding: 10, borderRadius: 100 }}
        >
          <ImageBackground
            source={require("../../assets/cargdor.gif")}
            style={{
              width: 100,
              height: 100,
            }}
          >
          </ImageBackground>
        </View>
      </View>:
      <div className="mx-auto container d-flex row justify-content-md-center">
      <div className="row justify-content-center">
      <div className="col-md-6 col-sm-12 col-xs-12"><EmpresasList/>
      {false&&<><Form.Control onChange={handleDateChange} max={maxDate} type="date"></Form.Control><Form.Control type="date" max={maxDate} min={minDate}></Form.Control></>}
      </div>
      </div>
      </div>}
<CajasPendientesList navigation={navigation}/>
    </View>
      </View>
  );
}

