import React, { useState, useEffect } from "react";
import {
  View,
  ImageBackground,Text
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import CabeceraSecundariaScreem from "../Components/CabeceraSecundariaScreem";
import { useCaja } from "../Components/uses/useMesas";

export default function VerCaja({route}) {
const {VerCaja_Abierta,CajaVista}=useCaja();
console.log("ROUTEEEE",route);
  useEffect(()=>{
    VerCaja_Abierta(route.params.IdPersonal,route.params.IdLocal)
  },[route])

  return (
    <View style={{backgroundColor:"#fff",flex:1}}>
      <CabeceraSecundariaScreem texto="Caja Abierta"  />
         <View style={{flex:1, margin:10, overflow:"scroll"}}>
            <CajaVista/>
            </View>
            </View>
  );
}

