import {
    consultasAPI_Get,
    consultasAPI_GetToken,
    consultasAPI_Post,
  } from "./conexionAPI";
  import {
    RegistrarToken
  } from "./InicioSesion";
  import { Alert } from "react-native";
  import { Usuario, UserLogin, CategoriaDelivery } from "../variables";
  import AsyncStorage from '@react-native-async-storage/async-storage'


  export function ListarEmpresas() {
   
        return consultasAPI_GetToken("api/Usuario/ListarEmpresas").then((result) => {
            return result
          })
          .catch((error) => {
            throw "Error: " + error;
          });
    }

    export function ListarLocales(idEmpresa) {
   
      return consultasAPI_GetToken("api/Usuario/ListarLocales1?idEmpresa="+idEmpresa).then((result) => {
          return result
        })
        .catch((error) => {
          throw "Error: " + error;
        });
  }

  
    export function SeleccionarEmpresa(IdEmpresa) {
     console.log(IdEmpresa);
          return consultasAPI_GetToken("api/Usuario/SeleccionarEmpresa?idEmpresa="+IdEmpresa).then((result) => {
            console.log(result);
            RegistrarToken(result)  
            return true
            })
            .catch((error) => {
              throw "Error: " + error;
            });
      }

      export function SeleccionarLocal(idLocal,Nombre) {
        console.log(idLocal,Nombre);
             return consultasAPI_GetToken("api/Usuario/SeleccionarLocal?idLocal="+idLocal+"&Local="+Nombre).then((result) => {
               console.log(result);
               RegistrarToken(result)  
               return true
               })
               .catch((error) => {
                 throw "Error: " + error;
               });
         }