import React, { useState, useEffect } from "react";

import { View, StyleSheet, Alert, SafeAreaView, Linking } from "react-native";
import { Icon } from "react-native-elements";
import {
  Avatar,
  Title,
  Caption,
  Paragraph,
  Drawer,
  Text,
  TouchableRipple,
} from "react-native-paper";
import {
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import {
  CerrarSesion,
  DatosInicialesToken,
} from "../utils/consultasAPI/InicioSesion";
import { Usuario, COLOR_EMPRESARIAL } from "../utils/variables";
import { useNavigation } from "@react-navigation/native";
let Iniciales = "";
let NombreCorto = "";
export default function ContentDrawer(props) {
  const [userInfo, setUserInfo] = useState(new Usuario());
  const [cambio, setCambio] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    DatosInicialesToken().then((e) => {
      if (e.Id === 0) {
        e.Nombres = "Anónimo";
        Iniciales = "";
        NombreCorto = "Anónimo";
      } else {
        // console.log(size(e.Nombres.trim().split(" ")), e.Nombres, e.Nombres);
       
          e.Nombres.toUpperCase()
            .split(" ")
            .forEach((x) => {
              Iniciales += x.substr(0, 1);
            });
          NombreCorto =
            e.Nombres.toUpperCase()
       
      }
      setUserInfo(e);
      //.log(userInfo);
    });
  }, [cambio]);
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",

      }}
    >
      <View
        style={{flex: 1, backgroundColor: COLOR_EMPRESARIAL }}
      >
        <DrawerContentScrollView {...props} style={{ flex: 1 }}>
          <TouchableRipple
            style={{
              backgroundColor: COLOR_EMPRESARIAL,
              marginTop: 0,
              paddingTop: 0,
              alignContent:"center"
            }}
           
            rippleColor="rgba(0, 0, 0, .7)"
          >
            <View
              style={{
                paddingHorizontal: 5,
                borderBottomColor: "#90e0ef",
                borderBottomWidth: 1,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <View
                  style={{
                    backgroundColor: "#fff",
                    padding: 5,
                    borderRadius: 50,
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                    <Avatar.Text
                      label={Iniciales.substr(0, 2)}
                      size={60}
                      style={{ backgroundColor: "#252158" }}
                      color={"#fff"}
                    />
                </View>
                <View
                  style={{
                    flex: 1,
                    marginLeft: 10,
                    flexDirection: "column",
                    overflow: "hidden",
                    alignSelf:"center"
                  }}
                >
                  <Title
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={{
                      margin: 0,
                      color: "#fff",
                      fontFamily: "FontRegular",
                      fontSize: 20,
                    }}
                  >
                    {NombreCorto.toUpperCase()}
                  </Title>
                </View>
              </View>
            </View>
          </TouchableRipple>
          <Drawer.Section>
            <DrawerItem
              labelStyle={{ color: "#fff", fontFamily: "FontRegular" }}
              icon={() => (
                <Icon
                  type="font-awesome"
                  name="stack-overflow"
                  color="#fff"
                  size={20}
                />
              )}
              onPress={() => {props.navigation.navigate("Categorias")}}
              label="Mesas"
            />
              <DrawerItem
              labelStyle={{ color: "#fff", fontFamily: "FontRegular" }}
              icon={() => (
                <Icon
                  type="font-awesome"
                  name="whatsapp"
                  color="#fff"
                  size={20}
                />
              )}
              onPress={() => {props.navigation.navigate("CajaAperturada")}}
              label="Cajas Aperturadas"
            />
          </Drawer.Section>
        </DrawerContentScrollView>
        <View />
        <Text
          style={{
            textAlign: "right",
            color: "#fff",
            fontFamily: "FontRegular",
            marginRight: 5,
          }}
        >
          v.
        </Text>
        <Drawer.Section
          style={{
            marginBottom: 10,
            borderTopWidth: 1,
            borderTopColor: "#90e0ef",
          }}
        >
          {userInfo.Id === 0 ? (
            <DrawerItem
              labelStyle={{ color: "#fff" }}
              icon={() => (
                <Icon
                  type="material-community"
                  name="account-circle-outline"
                  color="#fff"
                  size={20}
                />
              )}
              onPress={() => {
                props.navigation.navigate("InicioSesion", true);
              }}
              label="Iniciar Sesión"
            />
          ) : (
            <DrawerItem
              labelStyle={{ color: "#fff", fontFamily: "FontRegular" }}
              icon={() => (
                <Icon
                  type="material-community"
                  name="exit-to-app"
                  color="#fff"
                  size={20}
                />
              )}
              onPress={() => {
                CerrarSesion()
                  .then(() => {
                    props.navigation.replace("InicioSesion", true);
                  })
                  .catch(() => console.log("Incorrecto"));
              }}
              label="Cerrar Sesión"
            />
          )}
        </Drawer.Section>
      </View>
    </View>
  );
}

function screenOptions(name) {
  let iconName;
  switch (name) {
    case "Inicio":
      iconName = "home-outline";
      break;
    case "Favoritos":
      iconName = "star-outline";
      break;
    case "Cesta":
      iconName = "cart-outline";
      break;
    case "Compras":
      iconName = "format-list-checkbox";
      break;
    case "Cuenta":
      iconName = "account-circle-outline";
      break;
    case "Perfil":
      iconName = "account-circle-outline";
      break;

    default:
      break;
  }
  return (
    <Icon type="material-community" name={iconName} color="#fff" size={20} />
  );
}
