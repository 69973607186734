import React from "react";
import Categorias from "../screens/Categorias";
import InicioSesionInicial from "../screens/InicioSesionInicial";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import ContentDrawer from "./ContentDrawer";
import VerMesas from "../screens/VerMesa"
import StockProductos from "../screens/StockProductos"
import AgregarPedido from "../Components/AgregarPedido"
import CajaAperturada from "../screens/CajaAperturada";
import VerCaja from "../screens/VerCaja";
const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

export default function Navigation() {
  return (
    <Drawer.Navigator
      drawerContent={(props) => <ContentDrawer {...props} />}
      screenOptions={{headerShown: false}}
      initialRouteName="Inicio"
    >
      <Stack.Screen name="Inicio" component={Categorias} />
      <Stack.Screen name="Categorias" component={Categorias} />
      <Stack.Screen name="VerMesa" component={VerMesas} />
      <Stack.Screen name="StockProductos" component={StockProductos}/>
      <Stack.Screen name="CajaAperturada" component={CajaAperturada}/>
      <Stack.Screen name="VerCaja" component={VerCaja} />
      <Stack.Screen name="InicioSesion" component={InicioSesionInicial} />
    </Drawer.Navigator>
  );
}
