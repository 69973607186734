import React from "react";
import AsyncStorage from '@react-native-async-storage/async-storage'
import VistaInicial from "./viewInitial";
import Loading from "../Components/Loading";
export default class InicioSesionInicial extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      inicioSesion: false,
    };
  }
  componentDidMount() {
    const c_Token = async () => await AsyncStorage.getItem("Token");
    if (c_Token === null || c_Token === "")
      this.setState({ loading: false, inicioSesion: false });
    else {
      this.setState({ loading: false, inicioSesion: true });
    }
  }

  render() {
    return this.state.loading ? (
      <Loading isVisible={true} />
    ) : (
      <VistaInicial
        desdeCuenta={false}
        inicioSesion={this.state.inicioSesion}
      />
    );
  }
}
