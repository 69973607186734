import React, { useState, useEffect,useRef } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
} from "react-native";
import CabeceraSecundariaScreem from "../Components/CabeceraSecundariaScreem";
import Moment from "moment";
import { useNavigation } from "@react-navigation/native";
import LoadingTransparente from "../Components/LoadingTransparente";
import { COLOR_EMPRESARIAL} from "../utils/variables";
import {ImprimirPreCuenta, VerMesa, VerMesaHistorial} from "../utils/consultasAPI/Mesas"

export default function VerMesas({route}) {

  const { params } = route;
  const {  Nombre, Grupo } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [indexSelect,setIndexSelect]=useState(0);
  const [Mesa,setMesa]=useState(null);
  const [historial,setHistorial]=useState([]);
  const [idMesa,setIdMesa]=useState(params.Codigo);
  const [Regreso,setRegreso]=useState("");
  const navigation = useNavigation();
  
const Cargar= async ()=>
  {
    setIsLoading(true);
  try {
    const data = await Promise.all([
      VerMesa(params.Id),
      // Otras llamadas asíncronas si las tienes
    ]);
    const data1 = await Promise.all([
      VerMesaHistorial(params.Id),
      // Otras llamadas asíncronas si las tienes
    ]);
    setIdMesa(params.Codigo);
    setMesa(data[0]); 
    setHistorial(data1);
    setIsLoading(false);
  } catch (error) {
    console.log("errrrorrrrrr",error)
    setIsLoading(false);
  }
 }
console.log("IDMESA",idMesa);
console.log("HISTORIAL!! ",historial);

 useEffect(() => {
  const loadData = async() => {
    await Cargar();
  };

  navigation.addListener("focus", loadData);
  loadData();

  return () => {
    navigation.removeListener("focus", loadData);
  };
  },[params,Regreso]);
  console.log("mesa",Mesa);
    return(
    <View style={{backgroundColor:"#fff", flex:1}}>
      <CabeceraSecundariaScreem texto={(Mesa==null?"0":Nombre.replace("\n"," - ")+" - "+Grupo)}/>
      <div className="mx-auto container d-flex row justify-content-md-center">
      <div className="col-md-6 col-sm-12 col-xs-12">
        <Text style={{fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>Pedido Actual:</Text>
      {Mesa&&<View style={{flex:1, marginBottom:50}}>
      <FlatList
//          initialScrollIndex={indexSelect}
          showsVerticalScrollIndicator={true}
          data={Mesa.DetalleComanda}
          style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL,padding:10, borderWidth:1, borderRadius:5}}
          renderItem={(cate) => ( 
          <LineaCuota
          item={cate.item}
          indexSelect={indexSelect}
          index={cate.item.IDProducto}
          setIndexSelect={setIndexSelect}
          />)
          }
          keyExtractor={(item, index) => index.toString()}
        />
        <Text 
        style={{textAlign:"right", fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>TOTAL PEDIDO    S/ {Mesa.MontoTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
        <Text 
        style={{textAlign:"left", fontFamily:"FontRegular", fontSize:18, color:COLOR_EMPRESARIAL}}>Mozo: {Mesa.Personal}</Text>
        {Mesa.DetalleComanda.length>0&&
        <TiempoTranscurrido FechaPedido={Mesa.FechaPedido}/>}
      </View>}</div>
      <div className="col-md-6 col-sm-12 col-xs-12">
      <Text style={{fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>Historial:</Text>
      {historial&&<View style={{flex:1}}>
      <FlatList
      showsVerticalScrollIndicator={true}
      data={historial[0]}
      style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL,padding:10, borderWidth:1, borderRadius:5}}
      renderItem={(pedidoHistorial) => (
        <View style={{flex:1}}>
          <Text style={{width:"100%", fontFamily:"FontBold"}}>{Moment(pedidoHistorial.item.FechaPedido).format("DD/MM/yyyy hh:mm:ss a")}</Text>
          <Text style={{width:"100%", fontFamily:"FontBold"}}>Observación:</Text>
          <Text  style={{width:"100%"}}>{pedidoHistorial.item.Observacion}</Text>
          <FlatList
          showsVerticalScrollIndicator={true}
          data={pedidoHistorial.item.DetalleComanda}
          style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL,padding:10, borderWidth:1, borderRadius:5}}
          renderItem={(cate) => ( 
          <LineaCuota
          item={cate.item}
          indexSelect={indexSelect}
          index={cate.item.IDProducto}
          setIndexSelect={setIndexSelect}
          />)
          }
          keyExtractor={(item, index) => index.toString()}
        />
        <Text 
        style={{textAlign:"right", fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>TOTAL S/ {pedidoHistorial.item.MontoTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
        </View> 
        )
        }
        keyExtractor={(item, index) => index.toString()}
      />
      </View>}
      </div>
{isLoading&&<LoadingTransparente isVisible={true}/>}
      </div>
    </View>)
} 

const LineaCuota = ({ item, indexSelect, index }) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%" }}>{item.Cantidad.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", flex: 1 }}>{item.ProductoDetalleTotal}</Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%", textAlign: "right" }}>{item.Total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
    </View>
  );
};

const TiempoTranscurrido = (props) => {
  const [tiempoTranscurrido, setTiempoTranscurrido] = useState('');
  const [recargando,setRecargando]=useState(true);
  useEffect(() => {
    const horaEspecifica = new Date(props.FechaPedido);
    const intervalo = setInterval(() => {
      const tiempoActual = new Date();
      const diferenciaTiempo = tiempoActual - horaEspecifica;

      const horas = Math.floor(diferenciaTiempo / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutos = Math.floor((diferenciaTiempo / (1000 * 60)) % 60).toString().padStart(2, '0');
      const segundos = Math.floor((diferenciaTiempo / 1000) % 60).toString().padStart(2, '0');

      setTiempoTranscurrido(`${horas}:${minutos}:${segundos}`);
    }, 500);
  
    setRecargando(false);
    return () => clearInterval(intervalo);
  }, [props.FechaPedido]);

  return (
    <View>
      <Text style={{fontFamily:"FontLight", fontSize:16}}>Tiempo mesa activa: {tiempoTranscurrido}</Text>
    </View>
  );
};  