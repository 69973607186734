import React, { useState, useEffect,useRef } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList
} from "react-native";
import CabeceraSecundariaScreem from "../Components/CabeceraSecundariaScreem";
import LoadingTransparente from "../Components/LoadingTransparente";
import {COLOR_EMPRESARIAL} from "../utils/variables";
import Toast from "react-native-easy-toast";
import {SaldoProduccion} from "../utils/consultasAPI/Productos"
export default function StockProductos(props) {

  const toastRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [productos, setProductos]=useState([]);
  useEffect(
    () =>
      {
        setIsLoading(true);
        return SaldoProduccion().then((p)=>{setProductos(p);setIsLoading(false); console.log("CargoProductos",p[0])}).catch(()=>{setProductos([]);setIsLoading(false);})
      },
    [props]
  );
console.log(productos)
    return(<View style={{backgroundColor:"#fff", flex:1}}>
  <CabeceraSecundariaScreem texto="Stock Productos"/>
      <SafeAreaView style={{margin:5,marginBottom:20, flex:1}}>
      <View style={{flex:1}}>
      <FlatList
//          initialScrollIndex={indexSelect}
          showsVerticalScrollIndicator={true}
          data={productos}
          style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL, borderWidth:1, borderRadius:5}}
          renderItem={(cate) => ( 
            <View style={{flex:1, flexDirection:"row", marginBottom:5,marginHorizontal:10}}>
              <Text style={{color:cate.item.Cantidad<=0?"red":"#000", flex:1,fontFamily: "FontLight"}}>{cate.item.Producto.Nombre}</Text>
              <Text style={{color:cate.item.Cantidad<=0?"red":"#000",width:"20%",fontFamily: "FontLight", textAlign:"right"}}>{cate.item.Cantidad.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
          </View>)
          }
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
{isLoading&&<LoadingTransparente isVisible={true}/>}
<Toast
        position="center"
        positionValue={200}
        opacity={0.8}
        ref={toastRef}
      />              
      </SafeAreaView>
    </View>)
} 