import {
    conexionAPI,
    consultasAPI_sinToken,
    consultasAPI_conToken,
    consultasAPI_sinToken2,
    consultasAPI_sinToken3,
    consultasAPI_GetToken,
    consultasAPI_Post,
    consultasAPI_PostToken,
    Descarga_PostToken
  } from "./conexionAPI";
  import { Alert, Linking } from "react-native";
  import { Usuario, UserLogin, CategoriaDelivery } from "../variables";
  import AsyncStorage from '@react-native-async-storage/async-storage'
  
  export function ListarCajas_Aperturadas() {
    return consultasAPI_GetToken("Caja/AbiertaxEmpresa").then((result) => {
      if (result.resultado)  
        return result.Datos;
      else
      return result.mensaje
      })
      .catch((error) => {
        throw "Error: " + error;
      });
  }
  

  export function CajaAbierta_xPersonalxLocal(idPersonal,idLocal) {
    return consultasAPI_GetToken("Caja/SinRendirPersonalLocal?idPersonal="+idPersonal+"&idLocal="+idLocal).then((result) => {
      if (result.resultado)  
        return result;
      else
      return result.mensaje
      })
      .catch((error) => {
        throw "Error: " + error;
      });
  }