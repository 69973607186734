import React, { useState, useRef, useEffect } from "react";
import { View, Text, FlatList, ImageBackground,TouchableOpacity,SafeAreaView } from "react-native";
import { Button, Icon, SearchBar } from "react-native-elements";
import { COLOR_EMPRESARIAL } from "../../utils/variables";
import CabeceraSecundariaScreem from "../../Components/CabeceraSecundariaScreem";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from '@react-native-async-storage/async-storage'
import { size } from "lodash";
import Loading from "../../Components/Loading";
import CabeceraScreem from "../../Components/CabeceraScreem";
import { ListarEmpresas,SeleccionarEmpresa } from "../../utils/consultasAPI/Usuario";
import jwtDecode from "jwt-decode";

export default function ListaEmpresas() {
    const [isLoading, setIsLoading] = useState(false);
    const [empresas,SetEmpresas]=useState([])
    const navigation = useNavigation();
    useEffect(
        () =>
        {
          setIsLoading(true);
          AsyncStorage.getItem("Token").then(token=>{
            console.log("IdEmpresa", jwtDecode(token).IdEmpresa);
            console.log("userName", jwtDecode(token).unique_name);
            console.log("Tokenozeeee", token);
            if (token === null || token === "" || token === "null") {
              setIsLoading(false);
              return navigation.replace("InicioSesion");
             }
              else {
                ListarEmpresas().then(p=>{
                SetEmpresas(p);
                setIsLoading(false);
            }).catch(error=>{setIsLoading(false);})
            }
          });
          },
        []
      );
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <CabeceraScreem texto="Lista de Empresas" principal={true} />
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <View style={{ flex: 1,margin:10, backgroundColor: "#fff" }}>
                <FlatList
              ListHeaderComponent={
                <Text style={{
                    fontSize: 18,
                    color: "#555",
                    fontFamily: "FontBold",
                    marginTop: 10,
                  }}>Seleccionar empresa:</Text>
              }
              scrollEventThrottle={1}
              showsVerticalScrollIndicator={true}
              data={empresas}
              renderItem={(ddd) => (
                <TouchableOpacity
                onPress={()=>SeleccionarEmpresa(ddd.item.Id).then(p=>{if(p)return navigation.replace("MenuInicio");
            })}
                style={{margin:10, padding:5, backgroundColor:COLOR_EMPRESARIAL,borderRadius: 5
              }}><Text style={{
                fontFamily: "FontBold",
                fontSize: 18,
                color:"#fff"
              }}>{ddd.item.Razonsocial}</Text></TouchableOpacity>
              )}
              keyExtractor={(item, index) => index.toString()}
              />


        </View>

      </SafeAreaView>
      <Loading isVisible={isLoading}></Loading>
    </View>
  );
}
