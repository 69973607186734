import React, { useState, useEffect } from "react";
import {View} from 'react-native'
import {Tabs,Tab} from 'react-bootstrap';
import { useMesas } from "../uses/useMesas";
import { useNavigation } from "@react-navigation/native";

export default function Mesaboot(props){
    const {grupos,mesas}=useMesas();
    console.log("grupos en Mesaboot",grupos)
        const [key, setKey] = useState(grupos[0]);
        return (
            <div className="mx-auto container d-flex row justify-content-md-center">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            
          >
            {props.grupos.map((grupo)=>
                    (<Tab  key={grupo} eventKey={grupo} title={grupo}>
                        <div className="row justify-content-md-center">
                    {props.mesas.filter((p) => p.Grupo == grupo).map((mesita)=>(<Mesa
                    item={mesita} key={mesita.Nombre} navigation={props.navigation}
                width={500}
              />))
                }</div>
            </Tab>))}
          </Tabs></div>
        );
      }

      class Mesa extends React.PureComponent{
        
        render(){
          return<div
              onClick={()=>{console.log("item del clic",this.props.item); this.props.navigation.navigate("VerMesa",this.props.item) }}
              style={{
                margin:2,
                display:"flex",
                backgroundColor:this.props.item.Ocupado?"red":"green",
                borderRadius: 10,
                width: this.props.width / 5-5,
                height: this.props.width / 5 - 40,
                justifyContent:"center",
                alignItems:"center",
                textAlign:"center",
                fontFamily:
                    /*categoriaItem.item.Id === selectCategoria.IdCategoria
                      ? "FontBold"
                      :*/ "FontRegular",
                  color:
                    /*  categoriaItem.item.Id === selectCategoria.IdCategoria
                      ? "#fff"
                      :*/ "#fff", //COLOR_EMPRESARIAL,
                  fontSize: 13,
              }}
            >{this.props.item.Nombre}<br/>{this.props.item.Ocupado?"S/ "+this.props.item.Total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","):""}
            </div>
        }
      }