import React from "react";
import {
  StyleSheet,
  Image,
  View,
  ImageBackground,
} from "react-native";
import { Overlay } from "react-native-elements";

export default function Loading(props) {
  const { isVisible, text } = props;

  return (
    <Overlay
      isVisible={isVisible}
      //indowBackgroundColor="#F8F9FA"
      // overlayBackgroundColor="transparent"
      overlayStyle={styles.overlay}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignContent: "center",
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{ backgroundColor: "#fff", padding: 10, borderRadius: 100 }}
        >
          <ImageBackground
            source={require("../../assets/cargdor.gif")}
            style={{
              width: 100,
              height: 100,
            }}
          >
          </ImageBackground>
        </View>
      </View>
    </Overlay>
  );
}
const styles = StyleSheet.create({
  overlay: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },
  view: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: "#626262",
    //textTransform: "uppercase",
    marginTop: 10,
  },
  imagen: {
    width: "90%",
    // maxWidth: 350,
    height: 120,
  },

  cargador: {
    height: 100,
    width: 100,
  },
});
