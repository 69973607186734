  import React, { useRef, useEffect, useState } from "react";
  import {
    View,
    Text,
    StyleSheet,
    TouchableHighlight,
    StatusBar,Alert
  } from "react-native";
  import { Input, Icon, Button } from "react-native-elements";
  import { useNavigation } from "@react-navigation/native";
  import InicioSesion from "../Components/Cuenta/inicioSesion";
  import Logo_Cabecera from "../Components/Logo_Cabecera";
  import Toast from "react-native-easy-toast";
  import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
  import AsyncStorage from '@react-native-async-storage/async-storage'
  import Loading from "../Components/Loading";
  import ModalAbajo from "../Components/ModalAbajo"
  import { COLOR_EMPRESARIAL } from "../utils/variables";
  export default function VistaInicial(props) {
    const { desdeCuenta, handler } = props;
    const [modalActivo, setModalActivo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toastRef = useRef();
    const [URL,setURL]=useState("")
    const onChange = (e) => {
      setURL(e.nativeEvent.text);
    };
    async function fn_IniciarSesion() {
      await AsyncStorage.setItem("DireccionURL","https://apirest.ludfact.com/");
      setModalActivo(false);
    }
    useEffect(()=>{
      async function LeerURL()
      {
        fn_IniciarSesion();
        let dir=await AsyncStorage.getItem("DireccionURL");
        setURL(dir);
      }
      LeerURL();
      },[])
    return (<View style={{backgroundColor:"#fff", flex:1}}>
      <div className="mx-auto container d-flex row justify-content-md-center col-lg-6">
      <KeyboardAwareScrollView
        style={[desdeCuenta ? Styles.contenedor1 : Styles.contenedor]}
      >
        <View
          style={{
            top: 0,
            height: StatusBar.currentHeight+25,
            zIndex: 1001,
            backgroundColor: "#fff",
          }}
        />
        <View style={{flex:1}}>
          <Logo_Cabecera/>
          <InicioSesion
            toastRef={toastRef}
            desdeCuenta={desdeCuenta}
            handler={handler}
          />
        </View>
        <Toast ref={toastRef} position="center" Opacity={0.9} />
      </KeyboardAwareScrollView>
        <Loading isVisible={isLoading} />
      </div>
      </View>
    );
  }

  const Styles = StyleSheet.create({
    Opacity: {
      alignItems: "center",
      width: "95%",
    },
    contenedor: {
      flex: 1,
      //backgroundColor: "#fbfbfb",
      backgroundColor: "#fff",
      padding: 0,
      margin: 0,
    },
    contenedor1: {
      flex: 1,
      //backgroundColor: "#fbfbfb",
      backgroundColor: "#fff",
      padding: 0,
      margin: 0,
    },
    btnContainer: {
      width: "75%",
      alignSelf: "center",
      margin: 10,
    },

    ViewFooter: {
      flex: 1,
      flexDirection: "row",
      paddingTop: 10,

      justifyContent: "center",
    },
    ViewFooterText1: {
      color: "#626262",
      fontFamily: "FontRegular",
    },
    ViewFooterText2: {
      color: "#3b5999",
      fontFamily: "FontBold",
      //fontFamily: "FontBold",
    },
  });
