import React, { useState, useRef, useEffect } from "react";
import { View, Text, FlatList, ImageBackground,TouchableOpacity,SafeAreaView, Alert } from "react-native";
import { Button, Icon, SearchBar } from "react-native-elements";
import { COLOR_EMPRESARIAL } from "../../utils/variables";
import CabeceraSecundariaScreem from "../../Components/CabeceraSecundariaScreem";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from '@react-native-async-storage/async-storage'
import { size } from "lodash";
import Loading from "../../Components/Loading";
import CabeceraScreem from "../../Components/CabeceraScreem";
import { ListarLocales,SeleccionarEmpresa, SeleccionarLocal } from "../../utils/consultasAPI/Usuario";
import LoadingTransparente from "../../Components/LoadingTransparente";
import Toast from "react-native-easy-toast";
import { CerrarSesion } from "../../utils/consultasAPI/InicioSesion";
import jwtDecode from "jwt-decode";
export default function ListaLocales() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLocales, setIsLoadingLocales] = useState(false);
    const toastRef = useRef();
    const [empresas,SetEmpresas]=useState([])
    const navigation = useNavigation();

    async function ListaLocales()
    {
      setIsLoadingLocales(true);
      return ListarLocales().then(p=>{
        setIsLoadingLocales(false);
        console.log("pppp",p)
        if(p.length==1)
        {
          SeleccionarLocal(p[0].Id,p[0].Direccion).then(pp=>{if(pp)return navigation.replace("MenuInicio");})
        }
        else if(p.length>1)
        return p;
      else{
        Alert.alert("Advertencia","No devuelve una lista de locales para seleccionar.\n\nReintente o cierre sesión para que verifique sus credenciales.",
        [
          {
            text: "Reintentar",
            onPress: () => ListaLocales().then(p=>SetEmpresas(p)),
            style: "cancel",
          },
          {
            text: "Cerrar Sesión",
            onPress: () =>{
              CerrarSesion()
                  .then(() => {
                    navigation.replace("InicioSesion", true);
                    return [];
                  })
                  .catch(() => {console.log("Incorrecto");return [];});
            },
                    style:"cancel",
          },
        ],
        {
          cancelable: true,
          onDismiss: () =>
           console.log("Cancelado")
        })
      }
      })
      .catch(error=>{ 
        console.log("error",error);
        setIsLoadingLocales(false);
        if(error.toUpperCase().includes("NETWORK REQUEST FAILED"))
        Alert.alert("Error","problemas en la conexion al servidor. Puede reintentar o cerrar sesión para verificar la cadena de conexión.",
        [
          {
            text: "Reintentar",
            onPress: () => ListaLocales().then(p=>SetEmpresas(p)),
            style: "cancel",
          },
          {
            text: "Cerrar Sesión",
            onPress: () =>{
              CerrarSesion()
                  .then(() => {
                    navigation.replace("InicioSesion", true);
                    return [];
                  })
                  .catch(() => {console.log("Incorrecto");return [];});
            },
                    style:"cancel",
          },
        ],
        {
          cancelable: true,
          onDismiss: () =>
           console.log("Cancelado")
        })
      return [];
      })
    }

    console.log("lista locales",empresas)


    useEffect(
        () =>
        {
          setIsLoading(true);
          AsyncStorage.getItem("Token").then(token=>{
            console.log("IdEmpresa", jwtDecode(token).IdEmpresa);
            console.log("userName", jwtDecode(token).unique_name);
            console.log("Tokenozeeee", token);
            if (token === null || token === "" || token === "null") {
              setIsLoading(false);
              return navigation.replace("InicioSesion");
             }
             else
             {
              ListaLocales().then(p=>SetEmpresas(p))
              setIsLoading(false);
             }
             
          });
          },
        []
      );
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <CabeceraScreem texto="Lista de Locales" principal={true} />
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <View style={{ flex: 1,margin:10, backgroundColor: "#fff" }}>
                <FlatList
                refreshing={isLoadingLocales}
                onRefresh={()=>{
                  console.log("lista",ListaLocales());
                  ListaLocales().then(p=>SetEmpresas(p))}
                }
              ListHeaderComponent={
                <Text style={{
                    fontSize: 18,
                    color: "#555",
                    fontFamily: "FontBold",
                    marginTop: 10,
                  }}>Seleccionar local:</Text>
              }
              scrollEventThrottle={1}
              showsVerticalScrollIndicator={true}
              data={empresas}
              renderItem={(ddd) => (
                <TouchableOpacity
                onPress={()=>SeleccionarLocal(ddd.item.Id,ddd.item.Direccion).then(p=>{if(p)return navigation.replace("MenuInicio");
            })}
                style={{margin:10, padding:5, backgroundColor:COLOR_EMPRESARIAL,borderRadius: 5
              }}><Text style={{
                fontFamily: "FontBold",
                fontSize: 18,
                color:"#fff"
              }}>{ddd.item.CodEstablecimiento +" - "+ddd.item.Direccion}</Text></TouchableOpacity>
              )}
              keyExtractor={(item, index) => index.toString()}
              />


        </View>
        <Toast ref={toastRef} position="center" Opacity={0.9} />
      </SafeAreaView>
      <Loading isVisible={isLoading}></Loading>
    </View>
  );
}
