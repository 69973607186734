import React, { useState, useEffect } from "react";
import {
  View,
  ImageBackground,Text
} from "react-native";
import CabeceraScreem from "../Components/CabeceraScreem";
import { useNavigation } from "@react-navigation/native";
import Mesaboot from '../Components/boots/mesaboot'
import { useMesas,useEmpresas } from "../Components/uses/useMesas";
import Loading from "../Components/Loading"

export default function Categorias() {
  const {Lista_Mesas,mesas,grupos,recargando}=useMesas();
const {EmpresasList,LocalesList,Listar_Empresas,empresaSelect,localSelect,recargandoEmpresas}=useEmpresas();

  useEffect(()=>{
    if(localSelect.Id>0)
    {
      console.log("ListandoMesas");
      Lista_Mesas();
    }
  },[localSelect])

  console.log("mesas",mesas);
  console.log("grupos",grupos);

  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      Listar_Empresas();
  }, []);

  return (
    <View style={{backgroundColor:"#fff",flex:1}}>
      <CabeceraScreem texto="Reporte de Comprobantes"  />
         <View style={{flex:1, margin:10, overflow:"scroll"}}>
       {recargandoEmpresas? <Loading isVisible={recargandoEmpresas}/>:
      <div className="mx-auto container d-flex row justify-content-md-center">
      <div className="row justify-content-center">
      <div className="col-md-6 col-sm-12 col-xs-12"><EmpresasList/></div>
      <div className="col-md-6 col-sm-12 col-xs-12"><LocalesList/></div>
      </div>
      </div>}
      {recargando?<Loading isVisible={recargando} />:<Mesaboot grupos={grupos} mesas={mesas} navigation={navigation}/>}
    </View>
      </View>
  );
}


