import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import InicioSesionInicial from "../screens/InicioSesionInicial";
import Navigation from "./navigation";
import { createStackNavigator } from "@react-navigation/stack";
import ListaEmpresas from "../screens/Usuario/ListaEmpresas";
import ListaLocales from "../screens/Usuario/ListaLocales";


const Stack = createStackNavigator();

export default function MainNavegador(props) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {!props.Inicio ? (
        <Stack.Screen name="InicioSesion" component={InicioSesionInicial} />
      ) : (
        <Stack.Screen name="MenuInicio" component={Navigation} />
      )}
      {props.Inicio ? (
        <Stack.Screen name="InicioSesion" component={InicioSesionInicial} />
      ) : (
        <Stack.Screen name="MenuInicio" component={Navigation} />
      )}
      <Stack.Screen name="ListaEmpresas" component={ListaEmpresas} />
      <Stack.Screen name="ListaLocales" component={ListaLocales} />
    </Stack.Navigator>
  );
}
