import { size } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {   Animated, Dimensions, FlatList, Text, TouchableOpacity, View,Alert,SafeAreaView,ScrollView
} from "react-native";
import Toast from "react-native-easy-toast";
import { Button,Input,SearchBar  } from "react-native-elements";
import {  FontAwesome } from "react-native-vector-icons";
import CabeceraSecundariaScreem from "../Components/CabeceraSecundariaScreem";
import {  ListarAlmacenes, RegistrarComanda } from "../utils/consultasAPI/Mesas";
import { useNavigation } from "@react-navigation/native";
import { Lista, ListarCategorias,ListarProductosCombo,ListarProductosEstados,ListarProductosExtra } from "../utils/consultasAPI/Productos";
import {
  COLOR_EMPRESARIAL
} from "../utils/variables";
import LoadingTransparente from "./LoadingTransparente";
import ModalAbajo from "./ModalAbajo";
import Modal from "./Modal";
import AwesomeAlert from "react-native-awesome-alerts";

export default function AgregarPedido(props) {
  const refContainer3=useRef();
  const navigation=useNavigation();
  const [buscarProducto,setBuscarProducto]=useState("");
  const { width,height } = Dimensions.get("window");
  const [loadingTrans, setLoadingTrans] = useState(false);
  const [isVisible_Observacion, setisVisible_Observacion] = useState(false);
  const [isVisible_Combo, setisVisible_Combo] = useState(false);
  const [isVisible_Detalle, setisVisible_Detalle] = useState(false);
  const toastRef = useRef();
const [categorias,setCategorias]=useState([]);
const [listaProductos,setListaProductos]=useState([])
const [listaProductosFiltrado,setListaProductosFiltrado]=useState([]);
const [listaProductosCombo,setListaProductosCombo]=useState([])
  const scrollY = new Animated.Value(0);
const [productosPedido,setProductosPedido]=useState([]);
const [productosCombo,setProductosCombo]=useState([]);
const [clic,setClic]=useState(null);
const [clic1,setClic1]=useState(null);
const [clic2,setClic2]=useState(null);
const [productoClic,setProductoClic]=useState({});
const [clic3,setClic3]=useState(null);
const [productoClic1,setProductoClic1]=useState({});
const [productoClic2,setProductoClic2]=useState({});
const [productoClic3,setProductoClic3]=useState({});
const [productoComboSelect,setProductoComboSelect]=useState({});
const [pedido,setPedido]=useState({Observaciones:""})
const [Observaciones, setTObservacion] = useState("");
const [txtDetalle, setTxtDetalle] = useState("");
const [ListaAlmacenes,setListaAmacenes]=useState([]);
const [LocalSelect,setLocalSelect]=useState(0);
const [estado,setEstado]=useState(null);
const [showSeleccioneProductos,setShowSeleccioneProductos]=useState(false);
const [showMaximoCombo, setShowMaximoCombo]=useState(false);
const [showLimpiar, setShowLimpiar]=useState(false);
const [showRegistrar, setShowRegistrar]=useState(false);
const [showExito,setShowExito]=useState(false);
const [productosExtra,setProductosExtra]=useState([])
const [listaProductosExtra,setListaProductosExtra]=useState([])
const [productosExtraSelect,setProductoExtraSelect]=useState({})
const [isVisible_Extra,setIsVisible_Extra]=useState(false);
const [productosEstado,setProductosEstado]=useState([])
const [listaProductosEstado,setListaProductosEstado]=useState([])
const [productosEstadoSelect,setProductoEstadoSelect]=useState({})
const [isVisible_Estado,setIsVisible_Estado]=useState(false);
const [idIsColapsable,setIdColapsable]=useState(-1);
const [colapsableBuscando,setColapsableBuscando]=useState(true);
function ListarCategoriasProductos(_idAlmacen,_idLocal)
{
  setLocalSelect(_idAlmacen);
  setLoadingTrans(true);
  ListarCategorias(_idLocal).then((pp)=>{

    setCategorias(pp);
    Lista(_idAlmacen).then((ppp)=>{
      setListaProductos(ppp);
      setListaProductosFiltrado(ppp);
      setLoadingTrans(false);
    })
  })
  .catch((p) => {
    setListaProductos([]);
      setListaProductosFiltrado([]);
    setLoadingTrans(false);
    return "";
  })
}
  useEffect(() => {
    setLoadingTrans(true);
    ListarAlmacenes().then((Almacenes)=>{
      setListaAmacenes(Almacenes);
      ListarCategoriasProductos(Almacenes[0].Id,Almacenes[0].Empresa.Locales[0].Id);
    })
    setProductosPedido([]);
    setTObservacion("");
    setPedido({Observaciones:""});
    setBuscarProducto("");
  }, [props]);
  useEffect(() => {
    if(buscarProducto=="")
    {
          setListaProductosFiltrado(listaProductos);
    }
  else
  {
    let filtra=listaProductos.filter(p=>p.Nombre.includes(buscarProducto.toUpperCase()));
      setListaProductosFiltrado(filtra)
      console.log("LISTA",filtra)
}
  }, [buscarProducto]);

  useEffect(() => {
    if(clic!==null)
    {
      if(productoClic.agregar)
      {
          AgregarProducto(productoClic.producto);
        setClic(null);
        //toastRef.current.show("Producto agregado al pedido.",1000);
      }
      else
      {
        QuitarProducto(productoClic.producto);
        setClic(null);
       // toastRef.current.show("Producto quitado del pedido.",1000);
    }
  }
  }, [clic]);

  useEffect(() => {
    if(clic1!==null)
    {
      if(productoClic1.agregar)
      {
          AgregarProductoCombo(productoClic1.producto);
        setClic1(null);
        //toastRef.current.show("Producto agregado al pedido.",1000);
      }
      else
      {
        QuitarProductoCombo(productoClic1.producto);
        setClic1(null);
       // toastRef.current.show("Producto quitado del pedido.",1000);
    }
  }
  }, [clic1]);

  useEffect(() => {
    if(clic2!==null)
    {
      if(productoClic2.agregar)
      {
          AgregarProductoExtra(productoClic2.producto);
        setClic2(null);
        //toastRef.current.show("Producto agregado al pedido.",1000);
      }
      else
      {
        QuitarProductoExtra(productoClic2.producto);
        setClic2(null);
       // toastRef.current.show("Producto quitado del pedido.",1000);
    }
  }
  }, [clic2]);

  useEffect(() => {
    if(clic3!==null)
    {
      if(productoClic3.agregar)
      {
        console.log("agregar");
          AgregarProductoEstado(productoClic3.producto);
          setClic3(null);
          //toastRef.current.show("Producto agregado al pedido.",1000);
      }
      else
      {
        console.log("quitar");
        QuitarProductoEstado(productoClic3.producto);
        setClic3(null);
       // toastRef.current.show("Producto quitado del pedido.",1000);
    }
  }
  }, [clic3]);

  function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}


  function AgregarProducto(Cod) {
    let Cart=productosPedido;
    let index=Cart.findIndex(p=>p.Gui==Cod.Gui)
    if(index>=0)
    {
      Cart[index].Cantidad+=1
      setProductosPedido(Cart)

    }
    else if(Cod.EsCombo)
    {
      setProductosCombo([]);
      ListarProductosCombo(Cod.Id).then((p)=>setListaProductosCombo(p));
      setProductoComboSelect(Cod);
      setisVisible_Combo(true);
    }
    else if(Cod.EsExtra)
    {
      setProductosExtra([]);
      ListarProductosExtra(Cod.Id).then((p)=>setListaProductosExtra(p));
      setProductoExtraSelect(Cod);
      setIsVisible_Extra(true);
    }
    else if(Cod.EsEstado)
    {
      setProductosEstado([]);
      ListarProductosEstados(Cod.Id).then((p)=>setListaProductosEstado(p));
      setProductoEstadoSelect(Cod);
      setIsVisible_Estado(true);
    }
    else{
          Cart.push({Gui:generateUUID(),  index:Math.random(), Cantidad:1,Producto:Cod,PrecioUnitario:Cod._PrecioActual,});
          setProductosPedido(Cart)
    }
  }
  
  function AgregarDetalle(Cod)
  {
   setProductoComboSelect(Cod);
    setisVisible_Detalle(true);
  }
  function AgregarProductoCombo(Cod) {
    let Cart=productosCombo;
    if(Cart.length<productoComboSelect.MaxCombo)
     {     Cart.push(Cod);
          setProductosCombo(Cart);
     }
     else
     {
       setShowMaximoCombo(true);
     }
}
function AgregarProductoExtra(Cod) {
  let Cart=productosExtra;
  Cart.push(Cod);
  setProductosCombo(Cart);
}
function AgregarProductoEstado(Cod) {
  setEstado(Cod);
}
function QuitarProductoEstado(Cod) {
  setEstado(null);
}
function QuitarProductoExtra(Cod) {
    
  const id_index = productosExtra.findIndex((p) => p.Id == Cod.Id);
  let Cart=productosExtra;
        if (id_index >= 0) {
            Cart.splice(id_index, 1);
        }
        setProductosExtra(Cart);
}
function ContinuarProductoExtra() {
      let total=0;//productosExtra.reduce((ant,act) => { console.log("ant",ant._PrecioActual);console.log("act",act._PrecioActual); return ant+act._PrecioActual},0);
      let Texto="";
      let guiiid=generateUUID();
      let prooo=[]
      productosExtra.forEach(p=>{
        total+=p._PrecioActual;
        Texto+="\n+ "+p.Nombre;
        prooo.push({
          Gui:guiiid,
          Cantidad:1,
          Producto:{...p,["IdAlmacen"]:productosExtraSelect.IdAlmacen},
          PrecioUnitario:p._PrecioActual,
          EsExtra:true})
        p.Gui=guiiid
      })

    productosPedido.push({
      Gui:guiiid,
      Cantidad:1,
      Producto:{...productosExtraSelect,["_PrecioActual"]:productosExtraSelect._PrecioActual+total},
      PrecioUnitario:productosExtraSelect._PrecioActual ,
      Extras:prooo,
      EsExtra:false,ListaProductosCombo:Texto});
    setListaProductosExtra([]);
    setIsVisible_Extra(false);
}
function ContinuarProductoEstado() {

  if(estado)
  {
    let Texto="";
  Texto=estado?" - "+estado.Estado:"";
  let guiiid=generateUUID();
  productosPedido.push({
  Gui:guiiid,
  Cantidad:1,
  Producto:{...productosEstadoSelect,Estado:{Id:estado.Id}},
  PrecioUnitario:productosEstadoSelect._PrecioActual ,
  ListaProductosCombo:Texto});
  }
  else
  {
    let guiiid=generateUUID();
    productosPedido.push({
    Gui:guiiid,
    Cantidad:1,
    Producto:{...productosEstadoSelect},
    PrecioUnitario:productosEstadoSelect._PrecioActual});
  }
  
setListaProductosEstado([]);
setIsVisible_Estado(false);
setEstado(null);
}
function ContinuarProductoCombo() {
  if(productosCombo.length==productoComboSelect.MaxCombo)
   {
    let Max = 0;
    let TEXTO=productosCombo.map(p=>{return p.Nombre}).join("/");

      productosCombo.forEach((p) => {
        if(Max<p._PrecioActual)
        Max = p._PrecioActual;
      });

    productosPedido.push({Gui:generateUUID(),Cantidad:1,Producto:{...productoComboSelect,["_PrecioActual"]:Max},PrecioUnitario:Max ,ProductosCombo:productosCombo,EsCombo:true,ListaProductosCombo:TEXTO});
    setListaProductosCombo([]);
    setisVisible_Combo(false);
   }
   else
   {
     setShowMaximoCombo(true);
   }
}
  function QuitarProducto(Cod) {
    const id_index = productosPedido.findIndex((p) => p.Gui == Cod.Gui);
    let Cart=productosPedido;
          if (id_index >= 0) {
            if(Cart[id_index].Cantidad==1)
              Cart.splice(id_index, 1);
            else
            Cart[id_index].Cantidad-=1;
          }
          setProductosPedido(Cart);
  }
  function QuitarProductoCombo(Cod) {
    
    const id_index = productosCombo.findIndex((p) => p.Id == Cod.Id);
    let Cart=productosCombo;
          if (id_index >= 0) {
              Cart.splice(id_index, 1);
          }
          setProductosCombo(Cart);
  }
function Registrar()
{
  if(productosPedido.length>0)
  setShowRegistrar(true);
  else
    setShowSeleccioneProductos(true);  
}
function Limpiar()
{
  console.log("Limpiar")
  if(productosPedido.length>0)
  {
    console.log("Limpiar")
    setShowLimpiar(true);
    }
}

console.log("este",idIsColapsable);

  return (
    <>
    <CabeceraSecundariaScreem texto={"Agregar pedido - "+(props.route.params.emesa==null?"0":props.route.params.emesa.Nombre.replace("\n"," - ")+" - "+props.route.params.emesa.Grupo)}/>
    <SafeAreaView style={{ flex: 1,backgroundColor:"#fff" }}>     
          <View style={{flex:2,marginHorizontal:10 }}>
            <Text
            style={{fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}
            >Pedido:</Text>
              <FlatList
          showsVerticalScrollIndicator={true}
          data={productosPedido}
          style={{flex:1, borderColor:COLOR_EMPRESARIAL, paddingHorizontal:10, borderWidth:1, borderRadius:5}}
          renderItem={(cate) => ( 
          <LineaCuota
          item={cate.item}
          AgregarDetalle={AgregarDetalle}
          setClic={setClic}
          setProductoClic={setProductoClic}
          />)
          }
          keyExtractor={(item, index) => index.toString()}
        />
        <View style={{flexDirection:"row",alignContent:"space-between",marginTop:10}}>
            <TouchableOpacity onPress={()=>Limpiar()} ><FontAwesome name="trash" size={40} color={COLOR_EMPRESARIAL}/></TouchableOpacity><Text 
        style={{ flex:1,textAlignVertical:"center", textAlign:"right", fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>{"("+productosPedido.reduce((a,b)=>a+b.Cantidad,0)+") item(s)    => " +"TOTAL    S/ "}{ productosPedido.length>0?productosPedido.reduce((a,b)=>a+b.Cantidad*b.Producto._PrecioActual,0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","):(0).toFixed(2)}</Text>
          </View>
          <TouchableOpacity
                  onPress={() => setisVisible_Observacion(true)}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 10,
                  }}
                >
                  <View style={{ flex: 1,flexDirection:"row" }}>
                    <Text style={{ fontFamily: "FontBold",fontSize:15 }}>{"Observación:\t"}</Text>
                    <Text
                      numberOfLines={1}
                      style={{
                        marginBottom: 5,
                        paddingBottom: 5,
                        fontFamily: "FontRegular",
                        fontSize:15
                      }}
                    >{pedido.Observaciones}
                    </Text>
                  </View>
                </TouchableOpacity>
          <Button
          onPress={()=>Registrar()}
          titleStyle={{fontFamily:"FontBold", fontSize:18}} title={"AGREGAR PEDIDO"} buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginTop:5}} ></Button></View>
          {ListaAlmacenes.length>1&&<View style={{flexDirection:"row"}}>
          <TouchableOpacity
          onPress={()=>{
            setBuscarProducto("");
            ListarCategoriasProductos(ListaAlmacenes[0].Id,ListaAlmacenes[0].Empresa.Locales[0].Id);
            setColapsableBuscando(true)
          }}
                                style={{
                                  height:35,
                                  borderColor:COLOR_EMPRESARIAL,
                                  backgroundColor:ListaAlmacenes[0].Id==LocalSelect?COLOR_EMPRESARIAL:"#fff",
                                  borderWidth:2,
                                  borderRadius:10,
                                  margin:10,
                                  flex:1,
                                  alignItems:"center",
                                  justifyContent:"center"
                                }}
                              ><Text style={{color:ListaAlmacenes[0].Id==LocalSelect?"#fff":COLOR_EMPRESARIAL,
                                fontFamily: "FontBold",
                                fontSize: 15}}>{ListaAlmacenes[0].Empresa.Razonsocial}</Text></TouchableOpacity>
            <TouchableOpacity
            onPress={()=>{
              setBuscarProducto("");
              ListarCategoriasProductos(ListaAlmacenes[1].Id,ListaAlmacenes[1].Empresa.Locales[0].Id);
              setColapsableBuscando(true)
            }}
            style={{
              height:35,
              borderColor:COLOR_EMPRESARIAL,
              backgroundColor:ListaAlmacenes[1].Id==LocalSelect?COLOR_EMPRESARIAL:"#fff",
              borderWidth:2,
              borderRadius:10,
              margin:10,
              flex:1,
              alignItems:"center",
              justifyContent:"center"
            }}
            ><Text style={{color:ListaAlmacenes[1].Id==LocalSelect?"#fff":COLOR_EMPRESARIAL,
                                fontFamily: "FontBold",
                                fontSize: 15,alignSelf:"center"}}>{ListaAlmacenes[1].Empresa.Razonsocial}</Text></TouchableOpacity>
            </View>}
          <SearchBar
      placeholder='buscar producto...'
      autoCorrect={false}
      autoCapitalize="characters"
      lightTheme={true}
      onChange={(e)=>{setBuscarProducto(e.nativeEvent.text); setColapsableBuscando(e.nativeEvent.text.trim()=="")}}
      value={buscarProducto}
      onClear={()=>{setBuscarProducto("");setColapsableBuscando(true)}}
      labelStyle={{fontFamily:"FontLight"}}
      inputStyle={{backgroundColor:"#D9D9D9",color:"#444"}}
      inputContainerStyle={{backgroundColor:"#D9D9D9"}}
      containerStyle={{backgroundColor:"#fff"}}
    />
          <View style={{flex:1}}>
                    {size(listaProductosFiltrado) > 0 && (
                      <View style={{flex:1,marginHorizontal:10}}>
                        <FlatList
                          ref={refContainer3}
                          data={categorias}
                          renderItem={(itemm) => (
                            size(listaProductosFiltrado.filter(
                              (p) => p.Grupo.Nombre == itemm.item.Nombre
                            ))>0&&
                            <FlatList
                            ListHeaderComponent={
                              <TouchableOpacity
                                style={{
                                  height:50,
                                  borderColor: COLOR_EMPRESARIAL,
                                  borderWidth:2,
                                  borderRadius:10,
                                  marginTop:10,
                                  alignItems:"center",
              justifyContent:"center"
                                }}
                                onPress={()=>{console.log("i",itemm); itemm.item.Id==idIsColapsable?setIdColapsable(-1):setIdColapsable(itemm.item.Id); }}
                              ><Text style={{color:COLOR_EMPRESARIAL,
                                fontFamily: "FontBold",
                                fontSize: 15,alignSelf:"center"}}>{itemm.item.Nombre}</Text></TouchableOpacity>
                              }
                              style={{ width: width-20 }}
                              showsVerticalScrollIndicator={false}
                              data={listaProductosFiltrado.filter(
                                (p) => p.Grupo.Nombre == itemm.item.Nombre
                              )}
                              
                              renderItem={(ddd) => (
                                ((!colapsableBuscando)||idIsColapsable==itemm.item.Id)&&<ProductoItem cantidad={productosPedido.filter(p=>p.index==ddd.index).length>0?productosPedido.filter(p=>p.index==ddd.index)[0].Cantidad:0} setClic={setClic} producto={ddd.item} setProductoClic={setProductoClic} />
                              )}
                              keyExtractor={(item, index) => index.toString()}
                            />
                    )}
                          snapToAlignment="center"
                          scrollEventThrottle={16}
                          keyExtractor={(item, index) => index.toString()}
                        />
                      </View>
                    )}
          </View>
          <ModalAbajo
                isVisible={isVisible_Observacion}
                setIsVisible={setisVisible_Observacion}
                children={
                  <ViewObservacion
                    Seleccionado={pedido}
                    setSeleccionado={setPedido}
                    tObservacion={Observaciones}
                    setTObservacion={setTObservacion}
                    setIsVisible={setisVisible_Observacion}
                  />
                }
              />
               <Modal
                isVisible={isVisible_Combo}
                setIsVisible={setisVisible_Combo}
                children={
                  <View><Text style={{alignItems: "flex-start",
                  fontFamily: "FontBold",
                  color: "#444",
                  fontSize:18}}>Seleccionar {productoComboSelect.MaxCombo} Productos Combo - {productoComboSelect.Nombre}</Text>
                    {size(listaProductosCombo) > 0 && (
                      <View>
                            <FlatList
                            numColumns={2}
                            style={{height:height/2}}
                              showsVerticalScrollIndicator={false}
                              data={listaProductosCombo}
                              renderItem={(ddd) => (
                                <ProductoItemCombo cantidad={productosCombo.findIndex((p) => p.Id == ddd.item.Id)} setClic={setClic1} producto={ddd.item} setProductoClic={setProductoClic1} />
                              )}
                              keyExtractor={(item, index) => index.toString()}
                              onScroll={(e) =>{ 
                                scrollY.setValue(e.nativeEvent.contentOffset.y)
                              }}
                            />
                            <View>
                            <Button
                            onPress={()=>ContinuarProductoCombo()}
                            titleStyle={{fontFamily:"FontBold", fontSize:18}}
                            title={"CONTINUAR"}
                            buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginTop:10}}></Button>
                              </View>
                      </View>
                    ) }
                    </View>
                }
              />
              <Modal
                isVisible={isVisible_Extra}
                setIsVisible={setIsVisible_Extra}
                children={
                  <View><Text style={{alignItems: "flex-start",
                  fontFamily: "FontBold",
                  color: "#444",
                  fontSize:18}}>{"Seleccionar Adicionales\n->  "+productosExtraSelect.Nombre}</Text>
                    {size(listaProductosExtra) > 0 && (
                      <View>
                            <FlatList
                            numColumns={2}
                            style={{height:height/2}}
                              showsVerticalScrollIndicator={false}
                              data={listaProductosExtra}
                              renderItem={(ddd) => (
                                <ProductoItemExtra cantidad={productosExtra.findIndex((p) => p.Id == ddd.item.Id)} setClic={setClic2} producto={ddd.item} setProductoClic={setProductoClic2} />
                              )}
                              keyExtractor={(item, index) => index.toString()}
                              onScroll={(e) =>{ 
                                scrollY.setValue(e.nativeEvent.contentOffset.y)
                              }}
                              />
                            <View>
                            <Button
                            onPress={()=>ContinuarProductoExtra()}
                            titleStyle={{fontFamily:"FontBold", fontSize:18}}
                            title={"CONTINUAR"}
                            buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginTop:10}}></Button>
                              </View>
                      </View>
                    ) }
                    </View>
                }
              />
              <Modal
                isVisible={isVisible_Estado}
                setIsVisible={setIsVisible_Estado}
                children={
                  <View><Text style={{alignItems: "flex-start",
                  fontFamily: "FontBold",
                  color: "#444",
                  fontSize:18}}>{"Seleccionar Estado\n->  "+productosEstadoSelect.Nombre}</Text>
                    {size(listaProductosEstado) > 0 && (
                      <View>
                            <FlatList
                            numColumns={2}
                            style={{height:height/2}}
                              showsVerticalScrollIndicator={false}
                              data={listaProductosEstado}
                              renderItem={(ddd) => (
                                <ProductoItemEstado cantidad={estado?estado.Id==ddd.item.Id?1:0:0} setClic={setClic3} producto={ddd.item} setProductoClic={setProductoClic3} />
                              )}
                              keyExtractor={(item, index) => index.toString()}
                              onScroll={(e) =>{ 
                                scrollY.setValue(e.nativeEvent.contentOffset.y)
                              }}
                              />
                            <View>
                            <Button
                            onPress={()=>ContinuarProductoEstado()}
                            titleStyle={{fontFamily:"FontBold", fontSize:18}}
                            title={"CONTINUAR"}
                            buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginTop:10}}></Button>
                              </View>
                      </View>
                    ) }
                    </View>
                }
              />

<ModalAbajo
                isVisible={isVisible_Detalle}
                setIsVisible={setisVisible_Detalle}
                children={
                    <ViewDetalle
                    Seleccionado={productoComboSelect}
                    setSeleccionado={setProductoComboSelect}
                    tObservacion={txtDetalle}
                    productosPedido={productosPedido}
                    setProductosPedido={setProductosPedido}
                    setTObservacion={setTxtDetalle}
                    setIsVisible={setisVisible_Detalle}
                  />
               }
              />
          <Toast
                          position="bottom"
                          positionValue={50}
                          opacity={0.8}
                          ref={toastRef}
                        />
      <LoadingTransparente isVisible={loadingTrans} />
  <AwesomeAlert
          show={showRegistrar}
          showProgress={false}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          title="Alerta"
          message={"¿Esta seguro de registrar el pedido en la mesa "+props.route.params.emesa.Nombre+" - "+props.route.params.emesa.Grupo+"?"}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          showCancelButton={true}
          confirmText="Si"
          cancelTextText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowRegistrar(false);
          }}
          onConfirmPressed={() => {
            setLoadingTrans(true);
          RegistrarComanda(productosPedido,props.route.params.emesa,pedido.Observaciones)
          .then((XXXX)=>{ setLoadingTrans(false);
            setShowExito(true);
            })
          .catch((er)=>{ console.log("ER",er); setLoadingTrans(false)});
          setShowRegistrar(false);
          }}
        />
  <AwesomeAlert
          show={showLimpiar}
          showProgress={false}
          title="Alerta"
          message={"¿Esta seguro de limpiar la mesa "+(props.route.params.emesa==null?"0":props.route.params.emesa.Nombre.replace("\n"," - ")+" - "+props.route.params.emesa.Grupo)+"?"}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          showCancelButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Si"
          cancelText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowLimpiar(false);
          }}
          onConfirmPressed={() => {
            setLoadingTrans(true);
          setProductosPedido([]),
          setPedido({Observaciones:""});
          setLoadingTrans(false);
          setShowLimpiar(false);
          }}
        />
      <AwesomeAlert
          show={showMaximoCombo}
          showProgress={false}
          title="Error"
          message={"Solo puede seleccionar "+productoComboSelect.MaxCombo+ " productos"}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowMaximoCombo(false);
          }}
          onConfirmPressed={() => {
            setShowMaximoCombo(false);
          }}
        />
        <AwesomeAlert
          show={showExito}
          showProgress={false}
          title="Exito"
          message="Pedido agregado correctamente!"
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
          props.route.params.setRegreso(Date.now);
            navigation.goBack()
            setShowExito(false);
          }}
          onConfirmPressed={() => {
          props.route.params.setRegreso(Date.now);
            navigation.goBack()
            setShowExito(false);
          }}
        />
        <AwesomeAlert
          show={showSeleccioneProductos}
          showProgress={false}
          title="Error"
          message="Seleccione un producto para continuar con el registro."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowSeleccioneProductos(false);
          }}
          onConfirmPressed={() => {
            setShowSeleccioneProductos(false);
          }}
        />
    </SafeAreaView></>
  );
}

class ProductoItem extends React.PureComponent {
  render() { return(
    <TouchableOpacity
    onPress={()=>{
      if(this.props.producto.Activo)
      {
      this.props.setClic(Date.now);
      this.props.setProductoClic({agregar:true,producto:this.props.producto})}
    }}
      style={{
        flex: 1,
        paddingVertical: 3,
        marginHorizontal:5,
        marginBottom:5
      }}
    >
      <View
        style={{
          alignContent: "center",
          justifyContent: "space-around",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            borderColor: "#ddd",
            borderBottomWidth: 1,
            backgroundColor: this.props.cantidad>0?"#ddd":"#fff",
            borderRadius: 10,
            padding: 5,
          }}
        >
          <View
            style={{ 
              flex: 1,
              justifyContent: "space-between",
              marginBottom:5
            }}
          >
            <Text
              style={{
                alignItems: "flex-start",
                fontFamily: "FontBold",
                color: "#444",
                fontSize:14
              }}
            >{this.props.producto.Nombre}</Text>
            <View
              style={{
                justifyContent: "space-between",
                marginEnd: 0,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontFamily: "FontMedium",
                  flex: 1,
                  fontSize:14,
                  color: this.props.producto.Activo?"#444":"red",
                }}
              >
                Saldo: {this.props.producto.Activo?(this.props.producto.StockActual+(this.props.cantidad>0?" / "+this.props.cantidad:"")):"NO DISPONIBLE"}
              </Text>
                <Text
                  style={{
                    fontFamily: "FontBold",
                    flex: 1,
                    textAlign: "right",
                    color: "red",
                    fontSize:18
                  }}
                  
                >
                  S/ {parseFloat(this.props.producto._PrecioActual).toFixed(2)}
                </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>)
}
}
class ProductoItemCombo extends React.PureComponent {
  render() { return(
    <TouchableOpacity    
      style={{
        flex: 1,
        paddingVertical: 3,
        marginHorizontal:5,
        marginBottom:5
      }}
      onPress={()=>{this.props.setClic(Date.now);this.props.setProductoClic({agregar:!(this.props.cantidad>=0),producto:this.props.producto})}}
    >
      <View
        style={{
          alignContent: "center",
          justifyContent: "space-around",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            borderColor: "#ddd",
            borderWidth: 1,
            backgroundColor: this.props.cantidad>=0?"#ddd":"#fff",
            borderRadius: 10,
            padding: 5,
          }}
        >
          <View
            style={{ 
              flex: 1,
              marginLeft: 10,
              justifyContent: "space-between",
              marginBottom:5
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontFamily: "FontBold",
                color: "#444",
                fontSize:14
              }}
              numberOfLines={2}
            >{this.props.producto.Nombre}</Text>
            <Text
                  style={{
                    fontFamily: "FontBold",
                    flex: 1,
                    textAlign: "center",
                    color: "red",
                    fontSize:18
                  }}
                  
                >
                  S/ {parseFloat(this.props.producto._PrecioActual).toFixed(2)}
                </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>)
}
}
class ProductoItemExtra extends React.PureComponent {
  render() { return(
    <TouchableOpacity    
      style={{
        flex: 1,
        paddingVertical: 3,
        marginHorizontal:5,
        marginBottom:5
      }}
      onPress={()=>{this.props.setClic(Date.now);this.props.setProductoClic({agregar:!(this.props.cantidad>=0),producto:this.props.producto})}}
    >
      <View
        style={{
          alignContent: "center",
          justifyContent: "space-around",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            borderColor: "#ddd",
            borderWidth: 1,
            backgroundColor: this.props.cantidad>=0?"#ddd":"#fff",
            borderRadius: 10,
            padding: 5,
          }}
        >
          <View
            style={{ 
              flex: 1,
              marginLeft: 10,
              justifyContent: "space-between",
              marginBottom:5
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontFamily: "FontBold",
                color: "#444",
                fontSize:14
              }}
              numberOfLines={2}
            >{this.props.producto.Nombre}</Text>
            <Text
                  style={{
                    fontFamily: "FontBold",
                    flex: 1,
                    textAlign: "center",
                    color: "red",
                    fontSize:18
                  }}
                  
                >
                  S/ {parseFloat(this.props.producto._PrecioActual).toFixed(2)}
                </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>)
}
}
class ProductoItemEstado extends React.PureComponent {
  render() { return(
    <TouchableOpacity    
      style={{
        flex: 1,
        paddingVertical: 3,
        marginHorizontal:5,
        marginBottom:5
      }}
      onPress={()=>{  
      this.props.setClic(Date.now);this.props.setProductoClic({agregar:!(this.props.cantidad>0),producto:this.props.producto})}}
    >
      <View
        style={{
          alignContent: "center",
          justifyContent: "space-around",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            borderColor: "#ddd",
            borderWidth: 1,
            backgroundColor: this.props.cantidad>0?"#ddd":"#fff",
            borderRadius: 10,
            padding: 5,
          }}
        >
          <View
            style={{ 
              flex: 1,
              marginLeft: 10,
              justifyContent: "space-between",
              marginBottom:5
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontFamily: "FontBold",
                color: "#444",
                fontSize:14
              }}
            >{this.props.producto.Estado}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>)
}
}
class LineaCuota extends React.PureComponent{
  
  render(){
    return(
      <TouchableOpacity style={{marginVertical:2}} onLongPress={()=>this.props.AgregarDetalle(this.props.item)}>
        <View style={{flexDirection:"row",marginVertical:2,display:"flex", alignItems:"center"}}>
          <View style={{flexDirection:"row",width:"25%",display:"flex",alignItems:"center"}}>
          <TouchableOpacity
          style={{flex:1}} 
          onPress={()=>{this.props.setClic(Date.now);this.props.setProductoClic({agregar:false,producto:this.props.item})}} >
            <FontAwesome  name="minus-square"  size={30} color="red"/>
            </TouchableOpacity>
            <Text style={{ fontSize:18, fontFamily: "FontLight",flex:1,textAlign:"center",verticalAlign:"middle"}}>{this.props.item.Cantidad}</Text>
            <TouchableOpacity
            style={{flex:1}} 
          onPress={()=>{this.props.setClic(Date.now);this.props.setProductoClic({agregar:true,producto:this.props.item})}} >
            <FontAwesome  name="plus-square"  size={30} color="#217346"/>
            </TouchableOpacity>
            </View>
    <Text style={{fontFamily: "FontLight",flex:1}}>{this.props.item.Producto.Nombre+(this.props.item.Detalle?(this.props.item.Detalle!=""?" - "+this.props.item.Detalle:""):"")+(this.props.item.ListaProductosCombo?(this.props.item.ListaProductosCombo!=""?this.props.item.ListaProductosCombo:""):"")}</Text>
    <Text style={{fontFamily: "FontLight", width:"15%", textAlign:"right"}}>{(this.props.item.Producto._PrecioActual*this.props.item.Cantidad).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
    </View></TouchableOpacity>
)
  }
}
export function ViewObservacion(props) {
  let ref = useRef();
  useEffect(() => {
    setTimeout(() => ref.current.focus(), 100);
  }, []);
  const [text, setText] = useState(props.Seleccionado.Observaciones);
  //const [precio, setPrecio] = useState(props.Seleccionado.Producto._PrecioActual);
  return (
    <SafeAreaView style={{ margin: 10, flex: 1 }}>
      <View style={{ flex: 1 }}>
        <Text style={{ fontFamily: "FontBold", fontSize: 15 }}>
          Ingrese una observación:
        </Text>
        <ScrollView>
          <Input
            ref={ref}
            inputStyle={{
              fontFamily: "FontRegular",
              marginTop: 10,
            }}
            multiline={true}
            maxLength={100}
            placeholder="Observación..."
            defaultValue={text}
            blurOnSubmit={true}
            onChange={(e) => setText(e.nativeEvent.text)}
            onSubmitEditing={(e) => setText(e.nativeEvent.text)}
          />
        </ScrollView>
      </View>
      <Button
        buttonStyle={{ backgroundColor: COLOR_EMPRESARIAL }}
        titleStyle={{
          height: 30,
          fontFamily: "FontBoton",
          fontSize: 20,
          textAlignVertical: "center",
        }}
        title="Continuar"
        onPress={() => {
          props.setSeleccionado({
            ...props.Seleccionado,
            ["Observaciones"]: text,
          });
          props.setIsVisible(false);
        }}
      />
    </SafeAreaView>
  );
}
export function ViewDetalle(props) {
  let ref = useRef();
  useEffect(() => {
    setTimeout(() => ref.current.focus(), 1);
  }, []);
  const [text, setText] = useState(props.Seleccionado.Detalle);

  
  function GuardarDetalle()
  {
    var index = props.productosPedido.findIndex(p=>p.Gui==props.Seleccionado.Gui);
    var obj=props.productosPedido;
    obj[index].Detalle=text;
    props.setProductosPedido(obj);

  }
  return (
    <SafeAreaView style={{ margin: 10, flex:1  }}>
      <View style={{ flex:1 }}>
        <Text style={{ fontFamily: "FontBold", fontSize: 15 }}>
          {"Ingrese un detalle al producto\n->  "+props.Seleccionado.Producto.Nombre}
        </Text>
        <ScrollView>
          <Input
            ref={ref}
            inputStyle={{
              fontFamily: "FontRegular",
              marginTop: 10,
            }}
            multiline={true}
            maxLength={100}
            placeholder="Detalle..."
            defaultValue={text}
            blurOnSubmit={true}
            onChange={(e) => setText(e.nativeEvent.text)}
            onSubmitEditing={(e) => setText(e.nativeEvent.text)}
          />
                  </ScrollView>

      </View>
      <Button
        buttonStyle={{ backgroundColor: COLOR_EMPRESARIAL }}
        titleStyle={{
          height: 30,
          fontFamily: "FontBoton",
          fontSize: 20,
          textAlignVertical: "center",
        }}
        title="Continuar"
        onPress={() => {
          GuardarDetalle();
          props.setIsVisible(false);
        }}
      />
    </SafeAreaView>
  );
}