import {
  conexionAPI,
  consultasAPI_sinToken,
  consultasAPI_conToken,
  consultasAPI_sinToken2,
  consultasAPI_sinToken3,

} from "./conexionAPI";
import { Alert } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage'

import { ObtenerCliente_Token, ListarDirecciones } from "./Cliente";
import {
  Usuario,
  UserLogin,
  CategoriaDelivery,
  generateUUID,
} from "../variables";

export function SelectDireccionSeleccion() {
  return ListarDirecciones().then((lista) => {
    // console.log(lista);
    const index = lista.find((element) => element.Predeterminado);
    // console.log(index);
    return index;
  });
}

export function GrabarDireccionSeleccion(FormData) {
  return ObtenerCliente_Token()
    .then((Cliente) => {
      // console.log("DIRECCION JSON", JSON.stringify(FormData));
      return AsyncStorage.setItem(
        "direccion" + Cliente.ID,
        JSON.stringify(FormData)
      ).then(() => {
        return true;
      });
    })
    .catch(() => {
      return false;
    });
}

export function CarritoCompra_CantTotal() {
  return ObtenerCliente_Token()
    .then((Cliente) => {
      return AsyncStorage.getItem("cart" + Cliente.ID)
        .then((datacart) => {
          let cart = [];
          let Suma = 0;
          if (datacart !== null) {
            cart = JSON.parse(datacart);
            cart.forEach((p) => {
              Suma += p.cantidad;
            });
            return Suma;
          } else {
            return 0;
          }
        })
        .catch(() => {
          return 0;
        });
    })
    .catch(() => {
      return 0;
    });
}

export function CarritoCompra_ListaProductos() {
  let Prod = [];
  return ObtenerCliente_Token()
    .then((Cliente) => {
      return AsyncStorage.getItem("cart" + Cliente.ID)
        .then((datacart) => {
          let cart = [];
          let Suma = 0;
          if (datacart !== null) {
            cart = JSON.parse(datacart);
            console.log(JSON.stringify(cart));
            cart.forEach((p) => {
              Prod.push({ Id: p.item.Id, Cantidad: p.cantidad });
            });
            //   console.log("LSITA DE PRODUCTOSOSS>!!!!!!!!", Prod);

            return Prod;
          } else {
            //   console.log("LSITA DE PRODUCTOSOSS>!!!!!!!!", Prod);
            return Prod;
          }
        })
        .catch(() => {
          return Prod;
        });
    })
    .catch(() => {
      return Prod;
    });
}

export async function CarritoCompra_Aumentar(Ruc, item) {
  //console.log(
  //   "EMPRESA AL MOMENTO DE REGISSTRAR EL CARRITO DE COMPRAA!!!!! ",
  //  Ruc
  // );
  let itemCart = {
    item: item,
    ruc: Ruc,
    precio: item.Precio,
    cantidad: 1,
    observacion: "",
    cod: generateUUID(),
  };
  console.log(
    "SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS",
    itemCart
  );
  let Cliente = await ObtenerCliente_Token();
  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex(
          (p) =>
            p.item.Id == itemCart.item.Id &&
            p.item.IDSucursal == itemCart.item.IDSucursal &&
            p.item.IdAlmacen == itemCart.item.IdAlmacen
        );
        if (id_index >= 0) {
          cart[id_index].cantidad += 1;
        } else cart.unshift(itemCart);
      } else cart.unshift(itemCart);
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));

      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_AumentarObservacion(
  Ruc,
  item,
  Observacion,
  Cantidad
) {
  //console.log(
  // "EMPRESA AL MOMENTO DE REGISSTRAR EL CARRITO DE COMPRAA!!!!! ",
  // Ruc
  // );
  let itemCart = {
    item: item,
    ruc: Ruc,
    precio: item.Precio,
    cantidad: Cantidad,
    observacion: Observacion,
    cod: generateUUID(),
  };
  //console.log(
  //   "ITEM AGREGADOITEM AGREGADOITEM AGREGADOITEM AGREGADOITEM AGREGADOITEM AGREGADO",
  //  itemCart
  //);
  let Cliente = await ObtenerCliente_Token();
  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        cart.unshift(itemCart);
      } else cart.unshift(itemCart);
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));

      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_Observacion(item, Observacion) {
  let Cliente = await ObtenerCliente_Token();
  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex(
          (p) =>
            p.item.Id == item.Id &&
            p.item.IDSucursal == item.IDSucursal &&
            p.item.IdAlmacen == item.IdAlmacen
        );
        if (id_index >= 0) {
          cart[id_index].observacion = Observacion;
        }
      }
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));
      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_Quitar(Cod) {
  let Cliente = await ObtenerCliente_Token();
  console.log("COOODD", Cod);
  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex((p) => p.cod == Cod);
        if (id_index >= 0) cart.splice(id_index, 1);
      }
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));

      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_Sumar(Cod) {
  let Cliente = await ObtenerCliente_Token();

  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex((p) => p.cod == Cod);
        if (id_index >= 0) {
          cart[id_index].cantidad = cart[id_index].cantidad + 1;
        }
      }
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));
      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_Restar(Cod) {
  let Cliente = await ObtenerCliente_Token();

  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex((p) => p.cod == Cod);
        if (id_index >= 0) {
          if (cart[id_index].cantidad > 1)
            cart[id_index].cantidad = cart[id_index].cantidad - 1;
        }
      }
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));
      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_Agregar(Ruc, item, Observacion, Cantidad) {
  //console.log(
  // "EMPRESA AL MOMENTO DE REGISSTRAR EL CARRITO DE COMPRAA!!!!! ",
  // Ruc
  // );
  let itemCart = {
    item: item,
    ruc: Ruc,
    precio: item.Precio,
    cantidad: Cantidad,
    observacion: Observacion,
    cod: generateUUID(),
  };
  //console.log(
  //   "ITEM AGREGADOITEM AGREGADOITEM AGREGADOITEM AGREGADOITEM AGREGADOITEM AGREGADO",
  //  itemCart
  //);
  let Cliente = await ObtenerCliente_Token();
  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) cart = JSON.parse(datacart);
      cart.unshift(itemCart);
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));

      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_Disminuir(Ruc, item) {
  let itemCart = {
    item: item,
    ruc: Ruc,
    precio: item.Precio,
  };
  let Cliente = await ObtenerCliente_Token();

  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex((p) => p.Cod == itemCart.Cod);
        if (id_index >= 0) {
          if (cart[id_index].cantidad > 1)
            cart[id_index].cantidad = cart[id_index].cantidad - 1;
          else cart.splice(id_index, 1);
        }
      }
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));
      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_LimpiarEmpresa(IdSucursal) {
  let Cliente = await ObtenerCliente_Token();
  return CarritoCompra_Listar()
    .then((R) => {
      console.log("Lista de Carrito", JSON.stringify(R));
      console.log("Lista de Carrito", IdSucursal);
      var newList = R.filter((P) => P.ruc.Empresa.IdSucursal !== IdSucursal);
      // console.log("Nueva Lista", newList);
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(newList));
    })
    .catch((error) => console.log("EEEEEEERRRRRRRRR", error));
}

export async function CarritoCompra_Quitar2(Ruc, item) {
  let itemCart = {
    item: item,
    ruc: Ruc,
    precio: item.Precio,
  };
  let Cliente = await ObtenerCliente_Token();

  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex(
          (p) =>
            p.item.Id == itemCart.item.Id &&
            p.item.IDSucursal == itemCart.item.IDSucursal &&
            p.item.IdAlmacen == itemCart.item.IdAlmacen
        );
        if (id_index >= 0) {
          cart.splice(id_index, 1);
        }
      }
      AsyncStorage.setItem("cart" + Cliente.ID, JSON.stringify(cart));
      return true;
    })
    .catch((Error) => {
      return false;
    });
}

export async function CarritoCompra_Limpiar() {
  return ObtenerCliente_Token().then((pp) => {
    AsyncStorage.removeItem("cart" + pp.ID);
  });
}

export function CarritoCompra_Listar() {
  return ObtenerCliente_Token().then((pp) => {
    if (pp == null) return [];
    else {
      return AsyncStorage.getItem("cart" + pp.ID)
        .then((p) => {
          if (p === null) return [];
          else return JSON.parse(p);
        })
        .catch((error) => {
          throw error;
        });
    }
  });
}

export function BuscarCodigoPromocional(Codigo) {
  return ObtenerCliente_Token().then((pp) => {
    if (pp == null) return "Error al consultar datos del usuario.";
    else {

      return consultasAPI_sinToken3(
        "api/Empresa/CodigoPromocionalExiste",
        JSON.stringify({Codigo:Codigo,IdUsuario:pp.ID})
      )
        .then((result) => {
          console.log("6666", result);
          return result;
        })
        .catch((error) => {
          throw error;
        });

    
    }
  });
}

export async function CarritoCompra_ProductoCantidad(Ruc, item) {
  let itemCart = {
    item: item,
    ruc: Ruc,
    precio: item.Precio,
  };
  let Cliente = await ObtenerCliente_Token();

  return AsyncStorage.getItem("cart" + Cliente.ID)
    .then((datacart) => {
      let cart = [];
      if (datacart !== null) {
        cart = JSON.parse(datacart);
        const id_index = cart.findIndex(
          (p) =>
            p.item.Id == itemCart.item.Id &&
            p.item.IDSucursal == itemCart.item.IDSucursal &&
            p.item.IdAlmacen == itemCart.item.IdAlmacen
        );
        if (id_index >= 0) {
          return {
            cantidad: cart[id_index].cantidad,
            observacion: cart[id_index].observacion,
          };
        }
      }
      return {
        cantidad: 0,
        observacion: "",
      };
    })
    .catch((Error) => {
      return {
        cantidad: 0,
        observacion: "",
      };
    });
}
