import React, { useEffect, useState, Component } from "react";
import { StyleSheet } from "react-native";
import { COLOR_EMPRESARIAL } from "../variables";
export default StyleSheet.create({
  containerStyle: {
    width: "100%",
    marginBottom: 10,
  },

  labelStyle: {
    fontFamily: "FontLight",
    fontSize: 13,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: "100",
  },

  // estilo del texto que se digita
  inputStyle: {
    color: "#444",
    fontFamily: "FontRegular",
    fontSize: 16,
  },
  // crea el borde de la caja de texto
  inputContainerStyle: {
    borderWidth: 1,
    borderRadius: 30,
    paddingTop: 0,
    paddingLeft: 25,
    paddingBottom: 0,
    borderColor: COLOR_EMPRESARIAL,
    backgroundColor: "#FFFFFF",
    alignItems: "stretch",
    paddingHorizontal: 0,
  },
  // crea el icono izquierdo
  IconLeft: {
    marginRight: 10,
    marginLeft: -5,
  },
  rightIconContainerStyle: {
    marginRight: 15,
  },
});
