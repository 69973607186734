import {
  conexionAPI,
  consultasAPI_sinToken,
  consultasAPI_conToken,
  consultasAPI_sinToken3,
  consultasAPI_sinRespuesta,
  consultasAPI_conToken1
} from "./conexionAPI.js";
import { Alert } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Usuario, UserLogin, CategoriaDelivery } from "../variables";
import {
  DatosInicialesToken,
  RegistrarToken,
  IniciarSesion,
} from "./InicioSesion";

export function ObtenerCliente_Token() {
  return AsyncStorage.getItem("Token").then((p) => {
    if (p === null || p === "" || p === "null") {
      return null;
    } else {
      let jwtDecode = require("jwt-decode");
      return jwtDecode(p);
    }
  });
}

export function RegistrarDireccion(data, localizacion) {
  data = {
    ...data,
    ["Coordenadas"]: {
      latitude: localizacion.latitude,
      longitude: localizacion.longitude,
    },
  };
  console.log(data);
  return DatosInicialesToken()
    .then((e) => {
      data = { ...data, ["IdUsuario"]: e.Id };
      console.log("REGISTRO DE DIRECCION", JSON.stringify(data));
      return consultasAPI_conToken(
        "api/usuario/RegistrarDirecciones",
        JSON.stringify(data)
      )
        .then((result) => {
          console.log("RESULTRADO AL REGISTRAR DIRECCION", result);
          return result;
        })
        .catch((error) => {
          throw "Error al Registrar Direccion" + error;
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}

export function ActualizarDireccion(data, localizacion) {
  data = {
    ...data,
    ["Coordenadas"]: {
      latitude: localizacion.latitude,
      longitude: localizacion.longitude,
    },
  };
  console.log(data);
  return DatosInicialesToken()
    .then((e) => {
      data = { ...data, ["IdUsuario"]: e.Id };
      console.log("REGISTRO DE DIRECCION", JSON.stringify(data));
      return consultasAPI_conToken(
        "api/usuario/ActualizarDirecciones",
        JSON.stringify(data)
      )
        .then((result) => {
          console.log("RESULTRADO AL REGISTRAR DIRECCION", result);
          return result;
        })
        .catch((error) => {
          throw "Error al Registrar Direccion" + error;
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}

export function Seleccionar() {
  return DatosInicialesToken()
    .then((e) => {
      let data = { Id: e.Id };
      return consultasAPI_conToken("api/usuario/Seleccionar", data.Id)
        .then((result) => {
          console.log("RESULTRADO AL SELECCIONAR USUARIO", result);
          return result;
        })
        .catch((error) => {
          throw "Error al Seleccionar usuario " + error;
        });
    })
    .catch((error) => {
      throw "Error al Seleccionar usuario " + error;
    });
}

export async function RecordarContrasena(formData) {
  return await consultasAPI_sinToken(
    "api/usuario/RecordarContrasena",
    JSON.stringify({
      Correo: formData,
    })
  )
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw "error" + error;
    });
}
export function RegistrarDireccionPredeterminada(IdDireccion) {
  return DatosInicialesToken()
    .then((e) => {
      const data = { IdUsuario: e.Id, IdDireccion: IdDireccion };
      //  console.log("REGISTRO DE DIRECCION", JSON.stringify(data));
      return consultasAPI_conToken(
        "api/usuario/RegDireccionPredeterminada",
        JSON.stringify(data)
      )
        .then((result) => {
          //  console.log(
          //   "RESULTRADO AL REGISTRAR DIRECCION PREDETERMINADA",
          //    result
          // );
          return result;
        })
        .catch((error) => {
          throw "Error al Registrar Direccion Predeterminada" + error;
        });
    })
    .catch((error) => {
      throw (
        "Error al Obtener datos Iniciales  en Direccion Predeterminada" + error
      );
    });
}

export function ListarDirecciones() {
  return DatosInicialesToken()
    .then((e) => {
      return consultasAPI_conToken(
        "api/usuario/ListarDirecciones",
        JSON.stringify({ Id: e.Id })
      )
        .then((result) => {
          return JSON.parse(result);
        })
        .catch((error) => {
          throw "Error al Listar Direcciones" + error;
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}

export function EliminarDireccion(IdDireccion) {
  return DatosInicialesToken()
    .then((e) => {
      //  console.log({ IdUsuario: e.Id, IdDireccion: IdDireccion });
      return consultasAPI_conToken(
        "api/usuario/EliminarDireccion",
        JSON.stringify({ IdUsuario: e.Id, IdDireccion: IdDireccion })
      )
        .then((result) => {
          return JSON.parse(result);
        })
        .catch((error) => {
          throw "Error al Eliminar Direccion" + error;
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}

export async function RegistrarReferenciado(codigo) {
  console.log(JSON.stringify({referenciado:codigo}));
        return consultasAPI_conToken(
          "api/usuario/RegistrarReferenciado",
          JSON.stringify({Referenciado:codigo})
        )
          .then((result) => {
            if (result.cod === 1) return result.mensaje;
            else {
              throw result.mensaje;
            }
          })
          .catch((error) => {
            throw error;
          });
        }

        export async function BuscarCodigoPromocional(codigo) {
          console.log(codigo);
                return consultasAPI_conToken(
                  "api/usuario/CodigoPromocionalExiste",
                  JSON.stringify(codigo)
                )
                  .then((result) => {
                    if (result.cod === 1) return result.mensaje;
                    else {
                      throw result.mensaje;
                    }
                  })
                  .catch((error) => {
                    throw error;
                  });
                }
export async function ActualizarUsuario(FormData) {
  return DatosInicialesToken().then((p) => {
    FormData = { ...FormData, ["Id"]: p.Id };
    console.log(JSON.stringify(FormData));
    return consultasAPI_conToken(
      "api/usuario/ActualizarDatos",
      JSON.stringify(FormData)
    )
      .then((result) => {
        return JSON.parse(result);
      })
      .catch((error) => {
        throw "Error al Actualizar Datos" + error;
      });
  });
}

export async function RegistrarUsuario(FormData) {
  let tooken = await AsyncStorage.getItem("tokenMessaging");
  FormData = { ...FormData, ["Token"]: tooken };
  return await consultasAPI_sinToken(
    "api/usuario/Registrar",
    JSON.stringify(FormData)
  )
    .then((result) => {
      if (result === "") return false;
      else {
        if (result.Id < 0) {
          return false;
        } else if (result.Id === 0) {
          return false;
        } else {
          RegistrarToken(result.Token);
          return true;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      throw "No se puede registrar, el teléfono y/o el correo ya se encuentran registrados.";
      return false;
    });
}

export async function ActualizarContrasena(password_old, password_new) {
  var login = new UserLogin();

  const userActual = await DatosInicialesToken();
  login.user = userActual.Telefono;
  login.password = password_old;
  const FormData = {
    Id: userActual.Id,
    Password: password_new,
  };
  return IniciarSesion(login)
    .then((result_login) => {
      if (result_login) {
        return consultasAPI_conToken(
          "api/usuario/CambiarContrasena",
          JSON.stringify(FormData)
        )
          .then((result) => {
            if (result === "") return false;
            else {
              if (result === "1") {
                return true;
              } else return false;
            }
          })
          .catch((error) => {
            throw "Error al Actualizar el Password.";
          });
      } else throw "Contraseña Inválida.";
    })
    .catch((error) => {
      throw error;
    });
}

export async function ListarFavoritos(Coordenadas) {
  return DatosInicialesToken()
    .then((e) => {
      const FormData = { Coordenadas: JSON.stringify(Coordenadas), Id: e.Id };
      console.log("LISTAAAAaaaaR!!!!", JSON.stringify(FormData));
      return consultasAPI_conToken(
        "api/Usuario/ListarFavoritos",
        JSON.stringify(FormData)
      )
        .then((result) => {
          console.log(result);
          return JSON.parse(result);
        })
        .catch((error) => {
          console.log("Aquiii111!", error);
          throw "Error al Listar Empresas.";
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}
export function ContarAccesos()
{
  return DatosInicialesToken().then ((e)=>{return consultasAPI_conToken1("api/Usuario/ContarInicios").then((ee)=> {return ee})})
}

export async function ListarFavoritosRUC() {
  return DatosInicialesToken()
    .then((e) => {
      const FormData = { Id: e.Id };
      console.log("DDDDDDDDDDDDDDDDDDDDDDDDDDDgvdsgsdDDD", JSON.stringify(FormData));
      return consultasAPI_conToken(
        "api/Usuario/ListarFavoritossUCURSAL",
        JSON.stringify(FormData)
      )
        .then(async (result) => {
          console.log(
            "sdfgsdfgsdgsdfgsdghdsfghsdfhdfshsdfhsdfhdfhhsdfhdfshd",
            result
          );
          await AsyncStorage.setItem("Favoritos", result);
        })
        .catch((error) => {
          console.log("Aquiii!wwwww", error);
          throw "Error al Listar Empresas.";
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}

export async function RegistrarFavoritos(idEmpresa, idSucursal) {
  return DatosInicialesToken()
    .then((e) => {
      const FormData = {
        IdEmpresa: idEmpresa,
        Id: e.Id,
        IdSucursal: idSucursal,
      };
      return consultasAPI_conToken(
        "api/Usuario/RegistrarFavoritoSucursal",
        JSON.stringify(FormData)
      )
        .then(async (result) => {
          if (result === "true") {
            var x = await AsyncStorage.getItem("Favoritos");
            await AsyncStorage.getItem("Favoritos").then(t=>console.log("dssdsd",t));
            let Fav = [];

if(x)
          {
            Fav = JSON.parse(x.toString());
            Fav.unshift(idSucursal);
          }
          else{
            Fav.unshift(idSucursal);
          }
          await AsyncStorage.setItem("Favoritos", JSON.stringify(Fav));
            return result;
          } else return result;
        })
        .catch((error) => {
          throw "Error al Agregar Favorito." + error;
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}

export async function ListaFavoritosStorage() {
  var x = await AsyncStorage.getItem("Favoritos");
  return JSON.parse(x);
}

export async function QuitarFavoritos(idEmpresa) {
  return DatosInicialesToken()
    .then((e) => {
      const FormData = { IdSucusal: idEmpresa, Id: e.Id };
      return consultasAPI_conToken(
        "api/Usuario/QuitarFavoritoSucursal",
        JSON.stringify(FormData)
      )
        .then(async (result) => {
          if (result === "true") {
            var x = await AsyncStorage.getItem("Favoritos");
            let Fav = JSON.parse(x.toString());
            var yy = removeItemFromArr(Fav, idEmpresa);
            await AsyncStorage.setItem("Favoritos", JSON.stringify(yy));
            return result;
          } else return result;
        })
        .catch((error) => {
          throw "Error al Quitar Favorito.";
        });
    })
    .catch((error) => {
      throw "Error al Obtener datos Iniciales " + error;
    });
}

function removeItemFromArr(arr, item) {
  console.log(arr, item);
  return arr.filter(function (e) {
    return e !== item;
  });
}

export async function RegistrarHistorial(FormData) {
  return DatosInicialesToken()
    .then((e) => {
      FormData = { ...FormData, ["IdUsuario"]: e.Id };
      console.log("asaaaaaaaaaaaaaa", FormData);
      consultasAPI_sinRespuesta(
        "api/Usuario/RegistroHistorialCliente",
        JSON.stringify(FormData)
      );
    })
    .catch((error) => {
      throw "Error al Registrar datos Iniciales " + error;
    });
}
