import React, { useEffect, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage'
import MainNavigation from "../Navigation/mainNavegador";
import { NavigationContainer } from "@react-navigation/native";
import { ActualizarToken } from "../utils/consultasAPI/conexionAPI";

export default function Inicio(props) {
  const [Iniciado, setIniciado] = useState(props.InicioSesion);
  const { PrimeraVez, InicioSesion } = props;
  useEffect(() => {
    if (Iniciado) {
      ActualizarToken().then((o) => console.log("!ssss", o));
    /*  RegistrarHistorial({
        VApp: Platform.OS=='ios'?Constants.platform.ios.systemVersion:Constants.manifest.android.versionCode,
        VAndroid: Constants.systemVersion,
        Dispositivo: Constants.deviceName,
      });*/
    }
    AsignarPrimeraVez(PrimeraVez);
  }, []);

  return (
    <NavigationContainer>
      <MainNavigation Inicio={Iniciado} />
    </NavigationContainer>
  );
}

async function AsignarPrimeraVez(x) {
  await AsyncStorage.setItem("PrimeraVez", "false");
}
