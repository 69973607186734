import { useEffect, useState} from "react";
import {  ListarMesas } from "../../utils/consultasAPI/Mesas";
import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import { ListarEmpresas, ListarLocales, SeleccionarEmpresa, SeleccionarLocal } from "../../utils/consultasAPI/Usuario";
import { Dropdown } from "react-bootstrap";
import { CajaAbierta_xPersonalxLocal, ListarCajas_Aperturadas } from "../../utils/consultasAPI/Caja";
import Moment from "moment";
import {  FontAwesome5 } from "react-native-vector-icons";
import { COLOR_EMPRESARIAL } from "../../utils/variables";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Button } from "react-native-elements";
import WebView from "react-native-webview";
import Modal from "../Modal";
import { View } from "react-native";

export  function useMesas(){
    const [mesas, setMesas]=useState([]);
    const [grupos, setGrupos]=useState([]);
    const [recargando,setRecargando]=useState(false);
    const Lista_Mesas=()=>{
      setRecargando(true);
      ListarMesas().then((p)=>{
        console.log("esto es mesas",p)
        setMesas(p);
        const gruposUnicos = new Set();
        p.forEach(dato=>{
          gruposUnicos.add(dato.Grupo);
        })
        setGrupos([...gruposUnicos]);
        setRecargando(false);
  
      }).catch((ex)=>{ console.log("errror",ex); setRecargando(false);
    });
    }
  
    const Limpiar =()=>{
      setMesas([]);
    }
  
    return {mesas,grupos,Lista_Mesas,recargando,Limpiar};
  }

 export  function LeerToke() {
    return AsyncStorage.getItem("Token")
      .then((p) => {
        return p;
      })
      .catch((p) => {
        return "";
      });
  }

  export  function useEmpresas(){
    const [empresas, setEmpresas]=useState([]);
    const [locales, setLocales]=useState([]);
    const [empresaSelect, setEmpresaSelect]=useState({idEmpresa:0,Empresa:"No existe empresa"});
    const [localSelect, setLocalSelect]=useState({Id:0,Direccion:"No existe local"});
    const [recargandoEmpresas,setRecargandoEmpresas]=useState(false);
    
    const Listar_Empresas=()=>{
      setRecargandoEmpresas(true);
          AsyncStorage.getItem("Token").then(token=>{
            if (token === null || token === "" || token === "null") {
              setRecargandoEmpresas(false);
              return;
             }
              else {
                ListarEmpresas().then(p=>{
                setEmpresas(p);
                if(p.length>0){
                    Seleccionar_Empresa(p[0])
                }
                setRecargandoEmpresas(false);
            }).catch(error=>{
                setEmpresaSelect({idEmpresa:0,Empresa:"No existe empresa asignada"})
                setEmpresas()
                setRecargandoEmpresas(false);})
            }
          });
    }
    useEffect(()=>
    { 
      setRecargandoEmpresas(true);
      setLocalSelect({Id:0,Direccion:"No existe local"});
      if(empresaSelect.Id>0)
      {
        ListarLocales(empresaSelect.Id).then(p=>{
          setLocales(p);
          if(p.length>0)
            {
              SeleccionarLocal(p[0].Id,p[0].Direccion).then(()=>{setLocalSelect(p[0]); setRecargandoEmpresas(false)});
            }
          else
          setRecargandoEmpresas(false);
        });
  }},[empresaSelect])


  function Seleccionar_Empresa(empre)
  {
    setRecargandoEmpresas(true);
    return SeleccionarEmpresa(empre.Id).then(()=>{setEmpresaSelect(empre)}).finally(()=>setRecargandoEmpresas(false))
    
  }

  function Seleccionar_Locales(loca)
  {
    console.log("loca",loca);
    setRecargandoEmpresas(true);
    return SeleccionarLocal(loca.Id,loca.Direccion).then(()=>{setLocalSelect(loca)}).finally(()=>setRecargandoEmpresas(false))
  }


    function EmpresasList()
    {
        return (
            <Dropdown style={{zIndex:10001,margin:5}}>
  <Dropdown.Toggle split variant="success" id="dropdown-split-basic" className="d-flex col-12">
  <div  style={{ textAlign:"left", width:"100%",overflow: 'hidden', textOverflow: 'ellipsis' }}>
    {empresaSelect.Razonsocial}
    </div>
    </Dropdown.Toggle>
  <Dropdown.Menu>
    {empresas.length>0&&empresas.map((emp)=>
    (<Dropdown.Item key={emp.Id} onClick={()=>{Seleccionar_Empresa(emp); console.log("Este es la select:",emp.Razonsocial)}}>{emp.Razonsocial}</Dropdown.Item>)
    )}
  </Dropdown.Menu>
</Dropdown>)
    }

    function LocalesList()
    {
        return (
            <Dropdown style={{zIndex:10000,margin:5}} >
  <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" className="d-flex col-12">
  <div  style={{ textAlign:"left", width:"100%", overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {localSelect.Direccion}
    </div>
    </Dropdown.Toggle>
  <Dropdown.Menu>
    {locales.length>0&&locales.map((emp)=>
    (<Dropdown.Item key={emp.Id} onClick={()=>{Seleccionar_Locales(emp);}}>{emp.Direccion}</Dropdown.Item>)
    )}
  </Dropdown.Menu>
</Dropdown>)
    }
    return {empresaSelect,localSelect,Listar_Empresas,recargandoEmpresas,EmpresasList,LocalesList};
  }






  export  function useCaja(){
    const [Cajaspendientes, setCajasPendientes]=useState([]);
    const [recargandoCaja,setRecargandoCaja]=useState(false);
    const [CajaDetalle,setCajaDetalle]=useState([]);
    const [verVistaPrevia,setVerVistaPrevia]=useState(false)
    const Listar_Cajas=()=>{
      setRecargandoCaja(true);
      
      ListarCajas_Aperturadas().then(p=>{
        setCajasPendientes(p);
        console.log(p)
        setRecargandoCaja(false);
      })
    }

    const VerCaja_Abierta=(idPersonal,idLocal)=>{
      setRecargandoCaja(true);
      CajaAbierta_xPersonalxLocal(idPersonal,idLocal).then(p=>{
        setCajaDetalle(p);
        console.log(p)
        setRecargandoCaja(false);
      })
    }
console.log("DETALLEEE",CajaDetalle);
    function CajasPendientesList(props)
    {
        return (
          <div className="mx-auto container d-flex row justify-content-md-center">
          <table className="table table-hover table-striped" >
            <thead>
              <tr>
              <th
                    key="Ver"
                    align="left"
                    style={{ fontFamily:"FontBold", top: 0 }  }
                  >
                  </th>
                  <th
                    key="Fecha"
                    align="left"
                    style={{ fontFamily:"FontBold", top: 0 }  }
                  >
                    Local<br/>Fecha Apertura                
                  </th>
                  <th
                    key="Personal"
                    align="left"
                    style={{ fontFamily:"FontBold", top: 0 }  }
                  >
                    Personal                
                  </th>
                  <th
                    key="Monto"
                    align="right"
                    style={{   fontFamily:"FontBold", top: 0 }  }
                  >
                    Monto                
                  </th>
              </tr>
            </thead>
            <tbody>
              {Cajaspendientes.map((cajita)=>(
                <tr role="checkbox" tabIndex={-1} key={cajita.IdPersonal}>
                          <td style={{  verticalAlign: "middle" }} width={70} scope="row" key={cajita.FechaApertura+"Ver"} align="center">
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <TouchableOpacity
                  onPress={() => props.navigation.navigate("VerCaja",{IdPersonal:cajita.IdPersonal, IdLocal:cajita.IdLocal}) }>
            <FontAwesome5 
            style={{margin:5}} name="eye" size={20} color={COLOR_EMPRESARIAL}/></TouchableOpacity>
        </div>
                          </td>
                          <td scope="row" key={cajita.FechaApertura} align="left">
                          {cajita.Direccion}<br/>
                          {Moment(cajita.FechaApertura).format("DD/MM/yyyy hh:mm:ss a")}
                          </td>
                          <td scope="row align-middle" key={cajita.Personal} align="left">
                          {cajita.Personal}
                          </td>
                          <td scope="row align-middle" key={cajita.Monto} align="right">
                          {cajita.Monto.toFixed(2)}
                          </td>
                    </tr>
              ))}
            </tbody>
          </table>
      </div>
            )
    }
    const VistaPreviaCaja = () => {
      return (
        <div className="w-70 h-100 d-inline-block mx-auto container row justify-content-md-center" style={{overflow:"scroll"}}
         dangerouslySetInnerHTML={{__html:CajaDetalle.HTML}}
        />
      );
    }

    function CajaVista()
    {
        if(!verVistaPrevia)
          return (<div className="mx-auto container d-flex row justify-content-md-center">
          <><Button title={"Vista Previa"} onPress={()=>setVerVistaPrevia(true)}/>
          <table className="table table-hover table-striped" >
            <thead>
              <tr>
                  <th
                    key="Fecha"
                    align="left"
                    style={{ fontFamily:"FontBold", top: 0 }  }
                  >
                    Fecha                
                  </th>
                  <th
                    key="Personal"
                    align="left"
                    style={{ fontFamily:"FontBold", top: 0 }  }
                  >
                    Descripcion                
                  </th>
                  <th
                    key="Monto"
                    align="right"
                    style={{   fontFamily:"FontBold", top: 0 }  }
                  >
                    Monto                
                  </th>
                  <th
                    key="Personal"
                    align="left"
                    style={{ fontFamily:"FontBold", top: 0 }  }
                  >
                    Metodo Pago                
                  </th>
              </tr>
            </thead>
            <tbody>
              {CajaDetalle.Datos&&CajaDetalle.Datos.map((cajita)=>(
                <tr role="checkbox" tabIndex={-1} key={cajita.IdPersonal}>
                          <td scope="row" key={cajita.FechaRegistro} align="left">
                          {Moment(cajita.FechaApertura).format("DD/MM/yyyy hh:mm:ss a")}
                          </td>
                          <td scope="row align-middle" key={cajita.Descripcion} align="left">
                          {cajita.Descripcion}
                          </td>
                          <td scope="row align-middle" key={cajita.Monto} align="right">
                          {cajita.Monto.toFixed(2)}
                          </td>
                          <td scope="row align-middle" key={cajita.TFormaPago} align="left">
                          {cajita.TFormaPago}
                          </td>
                    </tr>
              ))}
            </tbody>
          </table></>
      </div>)
      else
      return(<Modal isVisible={verVistaPrevia} setIsVisible={setVerVistaPrevia}>
            <VistaPreviaCaja/>
            </Modal>
            )
    }
  
    return {CajasPendientesList,recargandoCaja,Listar_Cajas,VerCaja_Abierta,CajaVista};
  }
