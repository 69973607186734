import { registerRootComponent } from 'expo';
import React  from "react";
import Inicio from "./app/screens/Inicio";
import * as Font from "expo-font";
import {
  verificarToken,
} from "./app/utils/consultasAPI/InicioSesion";
import Loading from './app/Components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      fontsLoaded: false,
      PrimeraVez: true,
      InicioSesion: false,
      Version: 0,
      Conectado:true
    };
  }
  componentDidMount() {

    Font.loadAsync({
      FontLight: require("./assets/fonts/OpenSans/OpenSans-Light.ttf"),
      FontLightItalic: require("./assets/fonts/OpenSans/OpenSans-LightItalic.ttf"),
      FontBold: require("./assets/fonts/OpenSans/OpenSans-Bold.ttf"),
      FontBoldItalic: require("./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf"),
      FontMedium: require("./assets/fonts/OpenSans/OpenSans-Regular.ttf"),
      FontMediumItalic: require("./assets/fonts/OpenSans/OpenSans-Italic.ttf"),
      FontRegular: require("./assets/fonts/OpenSans/OpenSans-Regular.ttf"),
      FontBoton: require("./assets/fonts/MyriadPro/MyriadProBoldItalic.ttf"),
    }).then(()=>
    {
      verificarToken().then((pp) => {
        this.setState({
  fontsLoaded: true,
  PrimeraVez: true,
  InicioSesion: pp,
  Estado: true,
  Conectado:true,
  });
  });  
    }
    );
    

    /*
    await Network.getNetworkStateAsync().then(async (p) => {
      console.log("RED", p);
    });
    console.log("ddddddddddddd", Constants);
    console.log("Constants.deviceName", Constants.deviceName);
    console.log("Constants.nativeBuildVersion", Constants.nativeBuildVersion);
    console.log(
      "Constants.platform.android.versionCode",
      Constants.manifest.android.versionCode
    );
    console.log("Device.brand", Device.brand);
    console.log("Device.manufacturer", Device.manufacturer);
    console.log("Device.modelName", Device.modelName);
    console.log("Device.designName", Device.designName);
    console.log("Device.productName", Device.productName);
    console.log("Device.deviceYearClass", Device.deviceYearClass);

    console.log("Device.osName", Device.osName);
    console.log("Device.osVersion", Device.osVersion);
    console.log("Device.osBuildId", Device.osBuildId);
    console.log("Device.deviceName", Device.deviceName);
    */

  }

  render() {

    const {fontsLoaded}=this.state;
   // if(fontsLoaded)
    return (
          <Inicio
            PrimeraVez={this.state.PrimeraVez}
            InicioSesion={this.state.InicioSesion}
          />
    )
  }
}
registerRootComponent(App);
