import AsyncStorage from '@react-native-async-storage/async-storage'
import jwtDecode from "jwt-decode";



export async function ExisteConexion()
{
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
console.log("Esteesss",DIRECCION_API);
  const TimeoutPromise = (pr, timeout) =>
  Promise.race([pr, new Promise((_, rej) =>
    setTimeout(rej, timeout)
  )]);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  let pr = fetch(DIRECCION_API + "api/login/echoping", requestOptions)
  return TimeoutPromise(pr, 5000)
  .then(() => { return true})
.catch(() =>{ return false});
}


export async function conexionAPI(direccion, body) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
console.log("Esteesss",DIRECCION_API);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      AsyncStorage.setItem("Token", json.token);
      return json.token;
    })
    .catch(function (error) {
      console.log("ERRRORRRRR", error);
      throw "Error en el inicio de Sesion desde API: " +error;
    });
}

export async function conexionAPI_Res2(direccion, body) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      if (json.token == "") {
        throw json.resultado;
      } else AsyncStorage.setItem("Token", json.token);
      return json.token;
    })
    .catch(function (error) {
      console.log("ERRRORRRRR", error);
      throw "Error: " + error;
    });
}

export async function conexionAPI_Res(direccion, body) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      if (json.token == "") {
        if (json.respuesta == 2)
          throw "El número celular ya se encuentra registrado con otra cuenta. Intente recuperar su contraseña o comuniquesé con Yoco.pe.";
        else throw "Error al obtener token de registro. Vuelva a intentarlo.";
      } else AsyncStorage.setItem("Token", json.token);
      return json.token;
    })
    .catch(function (error) {
      console.log("ERRRORRRRR", error);
      throw "Error: " + error;
    });
}

export async function ActualizarToken() {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  const token = await AsyncStorage.getItem("Token");

  if (token === null || token === "" || token === "null") {
    AsyncStorage.removeItem("Token");
    return false;
  } else {

    if (jwtDecode(token).ID > 0) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);
      return true;
    } else {
      AsyncStorage.removeItem("Token");
      return false;
    }
  }
}

export async function consultasAPI_conToken(direccion, body) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "Bearer " + (await AsyncStorage.getItem("Token"))
  );
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      return json.resultado;
    })
    .catch(function (error) {
      throw error;
    });
}


export async function consultasAPI_conToken1(direccion) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "Bearer " + (await AsyncStorage.getItem("Token"))
  );
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      return json.resultado;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function consultasAPI_sinToken(direccion, body) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      return JSON.parse(json.resultado);
    })
    .catch(function (error) {
      throw error;
    });
}



export async function consultasAPI_GetToken(direccion) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + (await AsyncStorage.getItem("Token")));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: myHeaders,
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      return json;
    })
    .catch(function (error) {
      throw error;
    });
}


export async function consultasAPI_Get(direccion) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var requestOptions = {
    method: "GET",
  };
  console.log("direccion de envio:",DIRECCION_API + direccion);
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      return json;
    })
    .catch(function (error) {
      throw error;
    });
}
export async function consultasAPI_PostToken(direccion,post) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await AsyncStorage.getItem("Token")));

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: post,
    redirect: "follow",
  };
 
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      return json;
    })
    .catch(function (error) {
      throw error;
    });
}


export async function Descarga_PostToken(direccion,post) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Accept","application/octet-stream");
  myHeaders.append("Authorization", "Bearer " + (await AsyncStorage.getItem("Token")));
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    responseType: "blob",
    body: post,
    redirect: "follow",
  };

  return (await fetch(DIRECCION_API + direccion, requestOptions)).blob
}

export async function consultasAPI_Post(direccion,post) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: post,
    redirect: "follow",
  };
 
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      return json;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function consultasAPI_sinRespuesta(direccion, body) {

  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  await fetch(DIRECCION_API + direccion, requestOptions).then(status);
}

export async function consultasAPI_sinToken2(direccion) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      console.log("ddddwwwffwwwfddww1", json);
      return JSON.parse(json.resultado);
    })
    .catch(function (error) {
      throw error;
    });
}

export async function consultasAPI_sinToken3(direccion, body) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      console.log("wwwfddww2", json);
      return json;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function consultasAPI_sinToken4(direccion) {
  var DIRECCION_API =await AsyncStorage.getItem("DireccionURL");
  if(await AsyncStorage.getItem("DireccionURL")==null)
DIRECCION_API="https://apirest.ludfact.com/";
else
DIRECCION_API=await AsyncStorage.getItem("DireccionURL");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(DIRECCION_API + direccion, requestOptions)
    .then(status)
    .then(json)
    .then(function (json) {
      console.log("ddddwwwffwwwfddww3", json);
      return JSON.parse(json);
    })
    .catch(function (error) {
      throw error;
    });
}

function status(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else throw response.status;
}

function json(response) {
  return response.json();
}
export const userPass = {
  user: "",
  password: "",
};
