import React, { useEffect } from "react";
import {
  View,
  Text,
  StatusBar,
  TouchableOpacity,
  Platform
} from "react-native";
import { Icon } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { COLOR_EMPRESARIAL } from "../utils/variables";

export default function CabeceraScreem(props) {
  const navigation = useNavigation();
  const { texto, principal } = props;
 
  useEffect(() => {
    console.log("Cambio Actualizar");
   
  }, [props.Actualizar]);
  return (
    <View>
      <View
        style={{
          top: 0,
          height:Platform.OS=='ios'?35:StatusBar.currentHeight,
          zIndex: 100,
          backgroundColor: COLOR_EMPRESARIAL,
        }}
      />

      <View
        style={{
          height: 50,
          flexDirection: "row",
          paddingLeft: 20,
          backgroundColor: COLOR_EMPRESARIAL,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!principal&&
        <TouchableOpacity onPress={() => navigation.openDrawer()}>
          <View
            style={{
              borderRadius: 5,
              paddingHorizontal: 5,
            }}
          >
            <Icon
              type="material-community"
              name="menu"
              size={30}
              color="white"
            />
          </View>
        </TouchableOpacity>}
        <View
          style={{ flex: 1, justifyContent: "center", marginHorizontal: 10 }}
        >
          <Text
            style={{
              fontSize: 18,
              fontFamily: "FontBold",
              color: "white",
              textAlignVertical: "center",
            }}
            numberOfLines={1}
          >
            {texto}
          </Text>
        </View>
      </View>
    </View>
  );
}
